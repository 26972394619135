import React from 'react';

import Panel from './panel.jsx';
import MPMLParams from './mpml-params.jsx';
import EventLog from './event-log.jsx';

import './controller.css';

const Controller = ({
  unreadLogCount,
  currentTab,
  mpmlObject,
  eventLogs,
  onControlTabClick,
  onChangeMPMLGUI,
  onChangeMPMLJSON
}) => {
  return (
    <div className='vc-theme__controller'>
      <div className='vc-app__tabs'>
        <span className={`vc-app__tab${currentTab === 'panel' ? ' active' : ''}`} onClick={e => onControlTabClick('panel')}>GUI Controls</span>
        <span className={`vc-app__tab${currentTab === 'json' ? ' active' : ''}`} onClick={e => onControlTabClick('json')}>JSON</span>}
        <span className={`vc-app__tab${currentTab === 'log' ? ' active' : ''}`} onClick={e => onControlTabClick('log')}>Event Log{unreadLogCount !== 0 && <span className="new badge">{unreadLogCount}</span>}</span>
      </div>
      {currentTab === 'panel' && <Panel
        {...mpmlObject}
        onChangeMPMLGUI={(field, newVal) => onChangeMPMLGUI(field, newVal)} />}
      {currentTab === 'json' && <MPMLParams
        currentTab={currentTab}
        mpmlObject={mpmlObject}
        onChangeMPMLJSON={editor => onChangeMPMLJSON(editor)} />}
      {currentTab === 'log' && <EventLog
        logs={eventLogs} />}
    </div>
  );
};

export default Controller;
