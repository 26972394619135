import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import FilterContainer from "./filter-container";
import {getTimeDuration} from './visualisation/vis-utils';
import {fetchPopularVideosAPI, queryPopularVideosAPI} from "./analytics-service";

import './videos-analytics.css';

class VideosAnalytics extends Component {
  constructor (props) {
    super(props);

    this.state = {
      searchingQuery: false,
      popularVideosList: [],
      activeQuickDuration: '2w',
      filterOpen: false
    };
  }
  componentWillMount () {
    let {activeQuickDuration} = this.state;
    this.fetchPopularVideos(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration} = nextProps;
    if (this.props.activeQuickDuration !== activeQuickDuration) {
      this.fetchPopularVideos(activeQuickDuration);
    }
  }
  toggleFilterOpenState () {
    let {filterOpen} = this.state;
    filterOpen = !filterOpen;
    this.setState({
      filterOpen
    });
  }
  fetchPopularVideos (duration) {
    this.setState({
      searchingQuery: true
    });
    let time = getTimeDuration(duration);
    fetchPopularVideosAPI({time})
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        this.setState({
          searchingQuery: false,
          popularVideosList: buckets
        });
      })
      .catch(err => console.log(err));
  }
  fetchQueriedVideos (duration, query) {
    let time = getTimeDuration(duration);
    queryPopularVideosAPI(time, query)
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        this.setState({
          searchingQuery: false,
          popularVideosList: buckets
        });
      });
  }
  handleQuery (e) {
    let query = e.target.value.trim();
    let {activeQuickDuration} = this.state;
    if (e.keyCode === 13) {
      this.setState({
        searchingQuery: true
      });
      if (query.length > 1) {
        this.fetchQueriedVideos(activeQuickDuration, query);
      } else {
        this.fetchPopularVideos(activeQuickDuration);
      }
    }
  }
  setQuickInterval(interval) {
    this.setState({
      activeQuickDuration: interval.duration
    }, () => this.fetchPopularVideos(interval.duration));
  }
  render () {
    let {popularVideosList, searchingQuery} = this.state;
    return (
      <div className='vc-analytics-videos__container'>
        <div className='vc-analytics__filters'>
          <FilterContainer onSetQuickInterval={(interval) => this.setQuickInterval(interval)} />
        </div>
        <div className='mdc-text-field mdc-text-field--fullwidth'>
          <input type='text' className='mdc-text-field__input' placeholder='Enter video title/id (Press `Enter` to get results)' onKeyUp={(e) => this.handleQuery(e)} />
          <div className='mdc-text-field__bottom-line' />
        </div>
        {searchingQuery && <div role="progressbar" className="mdc-linear-progress mdc-linear-progress--indeterminate">
          <div className="mdc-linear-progress__buffering-dots" />
          <div className="mdc-linear-progress__buffer" />
          <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
            <span className="mdc-linear-progress__bar-inner" />
          </div>
          <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
            <span className="mdc-linear-progress__bar-inner" />
          </div>
        </div>}
        {!searchingQuery && popularVideosList.length > 0 && <table className='vc-analytics__table'>
          <thead>
          <tr>
            <th>Name</th>
            <th>Load Count</th>
          </tr>
          </thead>
          <tbody>
            {popularVideosList.map((video, index) => <tr key={index}>
              <td>{video[3].buckets[0] ? video[3].buckets[0].key : video.key}</td>
              <td>{video.doc_count}</td>
            </tr>)}
          </tbody>
        </table>}
      </div>
    );
  }
}

export default VideosAnalytics;
