import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import LoadViewToggle from './load-view-toggle.jsx';
import FilterContainer from './filter-container.jsx';
import VCPieChart from './visualisation/base-classes/vc-pie-chart.jsx';
import VCLineChart from './visualisation/base-classes/vc-line-chart.jsx';
import {fetchVideoAnalyticsAPI} from './analytics-service';
import {getTimeDuration} from './visualisation/vis-utils';
import {formatNumber} from "../utils/markup";

import './single-video-analytics.css';

const lastStateMap = {
  0: 'Ready',
  1: 'Playing',
  2: 'Paused',
  3: 'Ended'
};

class SingleVideoAnalytics extends Component {
  constructor (props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      vitals: {},
      cities: [],
      browsers: [],
      osDeviceDist: [],
      lastState: [],
      dateHistogram: [],
      histogramFields: [],
      histogramLabel: '',
      loadCount: [],
      loadCountFields: [],
      left: 0,
      right: 0,
      filter: 'load',
      interval: {
        duration: '2w'
      }
    };
  }
  componentDidMount () {
    let {width} = this.lineContainer.getBoundingClientRect();
    this.setState({
      width: 0.95 * width,
      left: 0.025 * width,
      right: 0.025 * width
    });
  }
  componentWillMount () {
    let {videoId} = this.props.match.params;
    this.fetchVideoData('2w', videoId);
  }
  fetchVideoData (duration, videoId, filter) {
    fetchVideoAnalyticsAPI({
      time: getTimeDuration(duration),
      videoId,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        res = res.map(item => item.data);
        let {lineData: dateHistogram, label: histogramLabel, fields: histogramFields} = this.getDateHistogram(res[5]);
        let {lineData: loadCount, fields: loadCountFields} = this.getLoadCount(res[6]);
        this.setState({
          vitals: this.getVitals(res[0]),
          cities: res[1].aggregations[2].buckets,
          browsers: res[2].aggregations[2].buckets,
          osDeviceDist: res[3].aggregations[2].buckets,
          lastState: res[4].aggregations[2].buckets.map(bucket => ({
            ...bucket,
            key: lastStateMap[bucket.key]
          })),
          dateHistogram,
          histogramLabel,
          histogramFields,
          loadCount,
          loadCountFields
        });
      });
  }
  getVitals (data) {
    let label = 'Seconds';
    let totalTimePlayed = data.aggregations[3].value;
    if (totalTimePlayed > 24 * 60 * 60) {
      totalTimePlayed /= 3600;
      label = 'Hours';
    }
    return {
      label,
      totalLoads: formatNumber(data.hits.total),
      uniqueViewers: formatNumber(data.aggregations[2].value),
      totalTimePlayed: formatNumber(totalTimePlayed)
    };
  }
  getDateHistogram (data) {
    let {buckets} = data.aggregations[2];
    let lineData = [];
    let fields = ['Total Covered', 'Total Played'];
    buckets.forEach(bucket => {
      let [date, timeString] = bucket.key_as_string.split('T');
      let time = timeString.split(':').slice(0, 2).join(':');
      lineData.push({
        key: `${date} ${time}`,
        'Total Covered': parseFloat((bucket[1].value / 60).toFixed(2)), // in minutes
        'Total Played': parseFloat((bucket[3].value / 60).toFixed(2)) // in minutes
      });
    });
    let label = 'in minutes';
    let maxTick = lineData.reduce((acc, curr) => {
      let maxForCurrent = curr['Total Covered'] > curr['Total Played'] ?
        curr['Total Covered'] :
        curr['Total Played'];
      return acc > maxForCurrent ? acc : maxForCurrent;
    }, 0);
    if (maxTick > 24 * 60) {
      label = 'in hours';
      lineData = lineData.map(data => ({
        ...data,
        'Total Covered': parseFloat((data['Total Covered'] / 60).toFixed(2)),
        'Total Played': parseFloat((data['Total Played'] / 60).toFixed(2))
      }));
    }
    return {
      lineData,
      label,
      fields
    };
  }
  getLoadCount (data) {
    let {filter} = this.state;
    let {buckets} = data.aggregations[2];
    let lineData = [];
    let fields = [`${filter === 'load' ? 'Load' : 'View'} Count`];
    buckets.forEach(bucket => {
      let [date, timeString] = bucket.key_as_string.split('T');
      let time = timeString.split(':').slice(0, 2).join(':');
      lineData.push({
        key: `${date} ${time}`,
        [fields[0]]: bucket.doc_count
      });
    });
    return {
      lineData,
      fields
    };
  }
  handleSetQuickInterval (interval) {
    let {videoId} = this.props.match.params;
    this.setState({
      interval
    });
    this.fetchVideoData(interval.duration, videoId);
  }
  handleViewFilterChange (filter) {
    let {videoId} = this.props.match.params;
    this.setState({
      filter
    }, () => this.fetchVideoData(this.state.interval.duration, videoId, filter));
  }
  render () {
    let {vitals, cities, browsers, osDeviceDist, lastState, dateHistogram, histogramFields, histogramLabel, loadCount, loadCountFields, width, left, right, filter} = this.state;
    return <div className='vc-analytics-single-video__container'>
      <Link className='vc-analytics__back' to='/analytics/videos'><i className='material-icons'>keyboard_arrow_left</i>All videos</Link>
      <div className='vc-analytics-single-video__video-id'><b>Video ID</b>: {this.props.match.params.videoId}</div>
      <div className='vc-analytics__filters'>
        <LoadViewToggle onFilterChange={(filter) => this.handleViewFilterChange(filter)} />
        <FilterContainer onSetQuickInterval={(interval) => this.handleSetQuickInterval(interval)} />
      </div>
      <div>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4 vc-helpers__center'>
              <div className='vc-analytics__vitals-heading'>Total {filter === 'load' ? 'Loads' : 'Views'}</div>
              <div className='vc-analytics__vitals-value'>{vitals.totalLoads}</div>
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4 vc-helpers__center'>
              <div className='vc-analytics__vitals-heading'>Total Unique Viewers</div>
              <div className='vc-analytics__vitals-value'>{vitals.uniqueViewers}</div>
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4 vc-helpers__center'>
              <div className='vc-analytics__vitals-heading'>Total {vitals.label} Played</div>
              <div className='vc-analytics__vitals-value'>{vitals.totalTimePlayed}</div>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
              <div className='vc-analytics__pie-heading'>Cities</div>
              <VCPieChart buckets={cities} />
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
              <div className='vc-analytics__pie-heading'>Browsers</div>
              <VCPieChart buckets={browsers} />
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
              <div className='vc-analytics__pie-heading'>OS and Device</div>
              <VCPieChart buckets={osDeviceDist} />
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
              <div className='vc-analytics__pie-heading'>Last State</div>
              <VCPieChart buckets={lastState} />
            </div>
          </div>
        </div>
      </div>
      <div className='vc-analytics-videos__line-chart' ref={c => this.lineContainer = c}>
        <VCLineChart lineData={dateHistogram} fields={histogramFields} label={histogramLabel} width={width} left={left} right={right} />
      </div>
      <div className='vc-analytics-videos__line-chart'>
        <VCLineChart lineData={loadCount} fields={loadCountFields} label='' width={width} left={left} right={right} />
      </div>
    </div>;
  }
}

export default SingleVideoAnalytics;
