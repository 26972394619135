import _ from 'lodash';

import getContinentCodeFromCountryCodes from './continent-codes';

const getPaddedTime = (time) => time < 10 ? '0' + time : time;

export const goToEl = (position) => {
  document.body.scrollTop = document.body.scrollTop + position;
};

export const formatNumber = (number) => number.toLocaleString('en-IN');

export const formatDuration = duration => {
  let hours = parseInt(duration / 3600, 10);
  duration = hours > 0 ? duration % 3600 : duration;
  let mins = parseInt(duration / 60, 10);
  let seconds = mins > 0 ? duration % 60 : duration;
  return `${hours > 0 ? getPaddedTime(hours) + ':' : ''}${mins > 0 ? getPaddedTime(mins) + ':' : ''}${(getPaddedTime(seconds))}`;
};

export const loadScript = (url, id, cb) => {
  return new Promise(resolve => {
    if (document.getElementById(id) === null) {
      let scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.src = url;
      scriptTag.id = id;
      scriptTag.onload = resolve;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    } else {
      resolve();
    }
  });
};

export const getPlaybackArray = (beats, duration, maxSticks = 200) => {
  let playbackArray = [];
  for (let i = 0; i < maxSticks; i++) {
    playbackArray.push(0);
  }
  let stickLength = duration / maxSticks;
  let startIndex = 0;
  let arrayInitialized = false;
  beats.forEach((beat, index) => {
    if (index !== beats.length - 1) {
      let nextBeat = beats[index + 1];
      if (nextBeat.pt - beat.pt !== (nextBeat.t - beat.t) / 1000) {
        let endIndex = Math.floor(beat.pt / stickLength);
        for (let i = startIndex; i <= endIndex; i++) {
          playbackArray[i] += 1;
        }
        startIndex = endIndex;
      } else {
        if (!arrayInitialized) {
          startIndex = Math.floor(beat.pt / stickLength);
          arrayInitialized = true;
        }
      }
    }
  });
  return playbackArray;
};

export const getColorScheme = (fraction) => {
  return fraction === 0 ? [240, 240, 240] : [parseInt(255 * fraction, 10), parseInt(255 * (1 - fraction), 10), 0];
};

export const getRegion = (data) => {
  let countryCodeArray = data.map(item => {
    let {country, countryCode} = item;
    countryCode = countryCode || (country === 'India' ? 'IN' : 'WO');
    return countryCode;
  });
  let uniqueCountryCodes = _.uniq(countryCodeArray);
  if (uniqueCountryCodes.length === 1) {
    return uniqueCountryCodes[0];
  } else {
    return getContinentCodeFromCountryCodes(uniqueCountryCodes);
  }
};
