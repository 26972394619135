import React, {Component} from 'react';

import {getTimeDuration} from './vis-utils';
import {fetchVitalsAPI} from '../analytics-service';
import {formatNumber} from '../../utils/markup';

class Vitals extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      label: 'Seconds',
      vitals: {}
    };
  }
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchVitals(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration, filter} = nextProps;
    if ((this.props.activeQuickDuration !== activeQuickDuration) || (this.props.filter !== filter)) {
      this.fetchVitals(activeQuickDuration, filter);
    }
  }
  fetchVitals (duration, filter = 'load') {
    let time = getTimeDuration(duration);
    fetchVitalsAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {vitals} = this.state;
        let {data} = res;
        let label = 'Seconds';
        let totalTimePlayed = data.aggregations[3].value;
        if (totalTimePlayed > 24 * 60 * 60) {
          totalTimePlayed /= 3600;
          label = 'Hours';
        }
        vitals = {
          totalLoads: formatNumber(data.hits.total.value),
          uniqueViewers: formatNumber(data.aggregations[2].value),
          totalTimePlayed: formatNumber(totalTimePlayed),
          uniqueVideos: formatNumber(data.aggregations[4].value)
        };
        this.setState({
          label,
          vitals
        });
      })
      .catch(err => console.log(err));
  }
  render () {
    let {vitals, label} = this.state;
    let {filter} = this.props;
    return (
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-helpers__center'>
            <div className='vc-analytics__vitals-heading'>Total {filter === 'load' ? 'Loads' : 'Views'}</div>
            <div className='vc-analytics__vitals-value'>{vitals.totalLoads}</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-helpers__center'>
            <div className='vc-analytics__vitals-heading'>Total Unique Viewers</div>
            <div className='vc-analytics__vitals-value'>{vitals.uniqueViewers}</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-helpers__center'>
            <div className='vc-analytics__vitals-heading'>Total {label} Played</div>
            <div className='vc-analytics__vitals-value'>{vitals.totalTimePlayed}</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-helpers__center'>
            <div className='vc-analytics__vitals-heading'>Total Unique Videos</div>
            <div className='vc-analytics__vitals-value'>{vitals.uniqueVideos}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Vitals;
