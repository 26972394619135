import React from 'react';
import Script from 'react-load-script';
import {Redirect, withRouter} from 'react-router-dom';

import {googleLoginAPI} from './login-service';
import {getQueryParams} from '../utils/routing';

import './login.css';

class Login extends React.Component {
  constructor (props) {
    super(props);

    const {search} = this.props.history.location;
    const queryParams = getQueryParams(search);
    const redirectLink = typeof queryParams.redirect !== 'undefined' ?
      queryParams.redirect : '/';

    this.state = {
      redirectLink,
      shouldRedirect: false,
      ssoToken: ''
    };
  }
  componentDidMount () {
    this.buttonWidth = this.vdoLoginButton.clientWidth;
    this.htmlLoaded = true;
    if (this.googleAPIScriptLoaded) {
      // this.renderGoogleSignIn();
    }
  }
  onLoadGoogleAPI () {
    this.googleAPIScriptLoaded = true;
    if (this.htmlLoaded) {
      // this.renderGoogleSignIn();
    }
  }
  onSuccess (googleUser) {
    let idToken = googleUser.getAuthResponse().id_token;
    googleLoginAPI(idToken)
      .then(res => {
        this.setState({
          ssoToken: res.data.loginHash,
          shouldRedirect: true
        });
      })
      .catch(err => console.log(err));
  }
  onFailure (err) {
    console.log(err);
  }
  renderGoogleSignIn () {
    let gapi = window.gapi;
    gapi.signin2.render('tpio-signin2', {
      'scope': 'profile email',
      'width': this.buttonWidth,
      'onsuccess': (googleUser) => this.onSuccess(googleUser),
      'onfailure': (err) => this.onFailure(err)
    });
  }
  render () {
    const {shouldRedirect, ssoToken, redirectLink} = this.state;
    return shouldRedirect ? <Redirect to={`/login/third-party/${ssoToken}?redirect=${redirectLink}`} /> : (
      <div className='vc-login'>
        <meta name="google-signin-client_id" content="829055855627-q2vrhrun4pp0hmmta8c2uc13jp97876k.apps.googleusercontent.com" />
        <h1 className='vc-helpers__center'>Login</h1>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell--span-4' />
            <div className='mdc-layout-grid__cell--span-4'>
              <div className='vc-login__container'>
                <a
                  ref={c => this.vdoLoginButton = c}
                  className='mdc-button mdc-button--raised vc-button__login vc-button--full-width'
                  href={`https://www.vdocipher.com/dashboard/themes?client=theplayerio&redirect=${document.location.origin}/login/sso/`}>
                  Login with VdoCipher
                </a>
                <div id="tpio-signin2" />
              </div>
            </div>
          </div>
        </div>
        <Script url='https://apis.google.com/js/platform.js' onLoad={() => this.onLoadGoogleAPI()} />
      </div>
    );
  }
}

export default withRouter(Login);
