import React, {Component} from 'react';

import LoadViewToggle from './load-view-toggle.jsx';
import Vitals from './visualisation/vitals.jsx';
import Browsers from './visualisation/browsers.jsx';
import Cities from './visualisation/cities.jsx';
import OSDeviceDistribution from './visualisation/os-device-distribution.jsx';
import LastStateDistribution from './visualisation/last-state-distribution.jsx';
import DateHistogram from './visualisation/date-histogram.jsx';
import LoadCount from './visualisation/load-count.jsx';
import CitiesTable from './visualisation/cities-table.jsx';
import PopularVideos from './visualisation/popular-videos.jsx';
import quickIntervals from './quick-intervals';

class OverviewAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuickDuration: '2w',
      filterOpen: false,
      filter: 'load'
    };
  }
  toggleFilterOpenState () {
    let {filterOpen} = this.state;
    filterOpen = !filterOpen;
    this.setState({
      filterOpen
    });
  }
  formatInputDateTime(epochTime) {
    let date = new Date(epochTime);
    return `${date.toLocaleDateString().split('/').reverse().join('-')}T${date.toLocaleTimeString()}`;
  }
  setQuickInterval(interval) {
    this.setState({
      activeQuickDuration: interval.duration
    });
  }
  handleViewFilterChange (filter) {
    this.setState({
      filter
    });
  }
  render () {
    let {activeQuickDuration, filterOpen, filter} = this.state;
    let filterInterval = quickIntervals.find(interval => interval.duration === activeQuickDuration);
    let columns = [quickIntervals.slice(0, 3), quickIntervals.slice(3, 6), quickIntervals.slice(6)];
    return (
      <div className='vc-analytics-overview__container'>
        <div className='vc-analytics__filters'>
          <LoadViewToggle onFilterChange={(filter) => this.handleViewFilterChange(filter)} />
          <div className='vc-analytics__filter-container vc-helpers__right'>
            <div className='vc-analytics__filter-label vc-helpers__right' onClick={() => this.toggleFilterOpenState()}>Filter: Last {filterInterval.label || ''}</div>
            <div className={`vc-analytics__filter-options${filterOpen ? ' vc-analytics__filter--open': ''}`}>
              <div className='mdc-layout-grid'>
                <div className='mdc-layout-grid__inner'>
                  {
                    columns.map((column, gridIndex) => <div key={gridIndex} className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                      {
                        column.map((interval, intervalIndex) => <div key={intervalIndex} onClick={() => this.setQuickInterval(interval)} className={`vc-analytics__quick-filter-item vc-helpers__center${activeQuickDuration === interval.duration
                            ? ' active'
                            : ''}`}>Last {interval.label}</div>)
                      }
                    </div>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-analytics__vitals--container vc-analytics__section'>
          <Vitals activeQuickDuration={activeQuickDuration} filter={filter} />
        </div>
        <div className='vc-analytics__distribution--container vc-analytics__section'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
                <div className='vc-analytics__pie-heading'>Cities</div>
                <Cities activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
                <div className='vc-analytics__pie-heading'>Browsers</div>
                <Browsers activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
                <div className='vc-analytics__pie-heading'>OS and Device</div>
                <OSDeviceDistribution activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3 vc-analytics__pie vc-helpers__center'>
                <div className='vc-analytics__pie-heading'>Last State</div>
                <LastStateDistribution activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-analytics__tc-tp--container vc-analytics__section' ref={c => this.histogramParent = c}>
          <h2 className='vc-helpers__center'>Total Covered vs. Total Played</h2>
          <DateHistogram parent={this.histogramParent} activeQuickDuration={activeQuickDuration}/>
        </div>
        <div className='vc-analytics__load-count--container vc-analytics__section' ref={c => this.loadCountParent = c}>
          <h2 className='vc-helpers__center'>Total {filter === 'load' ? 'Load' : 'View'} Count</h2>
          <LoadCount parent={this.loadCountParent} activeQuickDuration={activeQuickDuration} filter={filter}/>
        </div>
        <div className='vc-analytics__top-list--container vc-analytics__section'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-6'>
                <div className='vc-analytics__table-heading vc-helpers__center'>Top Cities</div>
                <CitiesTable activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-6'>
                <div className='vc-analytics__table-heading vc-helpers__center'>Top Popular Videos</div>
                <PopularVideos activeQuickDuration={activeQuickDuration} filter={filter}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewAnalytics;
