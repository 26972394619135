import React from 'react';
import {SketchPicker} from 'react-color';

const SassParamsEditor = ({
  sassValues,
  colorPickerShown,
  showColorPicker,
  hideColorPicker,
  onChangeSassValueType,
  onChangeSassValues
}) => {
  let sassKeys = Object.keys(sassValues);
  return (
    <div style={{
        border: '1px solid #DEDEDE',
        padding: 10
      }} className='sass-params-form'>
      {sassKeys.map((sassKey, index) => {
        let value = sassValues[sassKey].value;
        let type = sassValues[sassKey].type;
        return <div key={index} className='input-field'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-5'>
                <div className='param'>{sassKey}</div>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-7 vc-helpers__center'>
                {type === 'boolean' && <div className='switch'>
                  <label>
                    False
                    <input type='checkbox' defaultChecked={value === 'true'} onClick={(e) => onChangeSassValues(e.target.checked, sassValues[sassKey].line)} />
                    <span className='lever' />
                    True
                  </label>
                </div>}
                {type === 'color' && <div className='color-picker-container'>
                  <div className='selected-color-container vc-helpers__center'>
                    <span className='selected-color' style={{backgroundColor: value}} onClick={() => showColorPicker()} />
                  </div>
                  {colorPickerShown && <div>
                    <div className='picker-overlay' onClick={(e) => hideColorPicker()} />
                    <SketchPicker
                      color={value}
                      onChange={(color) => onChangeSassValues(color.hex, sassValues[sassKey].line)} />
                  </div>}
                </div>}
                {type === 'number' && <div className='mdc-textfield'>
                  <input
                    className='mdc-textfield__input'
                    type='number'
                    defaultValue={value.split('px')[0]}
                    onChange={(e) => onChangeSassValues(e.target.value.trim(), sassValues[sassKey].line)} />
                </div>}
                {type === 'generic' && <div className='mdc-textfield'>
                  <input
                    className='mdc-textfield__input'
                    type='text'
                    defaultValue={value}
                    onChange={(e) => onChangeSassValues(e.target.value.trim(), sassValues[sassKey].line)} />
                </div>}
              </div>
            </div>
          </div>
        </div>;
      })}
    </div>
  );
};

export default SassParamsEditor;
