import React from 'react';

class EventLog extends React.Component {
  componentDidMount () {
    this.eventLogDiv.scrollTop = this.eventLogDiv.scrollHeight;
  }
  componentDidUpdate () {
    this.eventLogDiv.scrollTop = this.eventLogDiv.scrollHeight;
  }
  render () {
    const {logs} = this.props;
    return (
      <div ref={c => this.eventLogDiv = c} className='event-log-container'>
        <table className='vc-table__event-log'>
          <tbody>
            {logs.map((log, idx) => {
              return <tr key={idx}>
                <td>{log.count || ''}</td>
                <td>{log.time.toLocaleTimeString()}</td>
                <td>{log.eventType}</td>
                <td>{log.eventHandler}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default EventLog;
