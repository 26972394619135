import React from 'react';
import md5 from 'md5';

import './gravatar.css';

const Gravatar = ({email}) => {
  const avatarHash = md5(email.trim().toLowerCase())
  return (
    <div className='vc-gravatar'>
      <img
        src={`https://www.gravatar.com/avatar/${avatarHash}`}
        alt={email} />
    </div>
  );
};

export default Gravatar;
