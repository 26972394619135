import React from 'react';
import {withRouter} from 'react-router-dom';

import Explore from './explore.jsx';
import {fetchCommunityThemesAPI, forkCommunityThemeAPI} from './explore-service';
import {getAccessToken} from '../utils/auth';

import './explore.css';

class ExploreContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isForking: false,
      communityThemes: []
    };
  }
  componentWillMount () {
    let accessToken = getAccessToken();
    this.setState({
      isLoggedIn: !!accessToken
    });
    fetchCommunityThemesAPI()
      .then(res => {
        this.setState({
          communityThemes: res.data.map(theme => {
            let {createdAt, updatedAt, ...others} = theme;
            createdAt = new Date(createdAt);
            createdAt = createdAt.toDateString() + ' ' + createdAt.toLocaleTimeString();
            updatedAt = new Date(updatedAt);
            updatedAt = updatedAt.toDateString() + ' ' + updatedAt.toLocaleTimeString();
            return {
              ...others,
              createdAt,
              updatedAt
            };
          })
        });
      })
      .catch(e => console.log(e));
  }
  handleEditButtonClick (themeId) {
    if (this.state.isLoggedIn) {
      this.setState({
        isForking: true
      });
      forkCommunityThemeAPI(themeId)
        .then(res => {
          this.setState({
            isForking: false
          });
          let newThemeHash = res.data.id;
          this.props.history.push({
            pathname: `/theme/${newThemeHash}`
          });
        })
        .catch(err => {
          console.log(err);
          this.setState({
            isForking: false
          });
        });
    } else {
      this.props.history.push({
        pathname: '/login',
        search: `redirect=/explore`
      });
    }
  }
  render () {
    const {isLoggedIn, isForking, communityThemes} = this.state;
    return <Explore
      isForking={isForking}
      isLoggedIn={isLoggedIn}
      communityThemes={communityThemes}
      onEditButtonClick={(themeId) => this.handleEditButtonClick(themeId)} />;
  }
}

export default withRouter(ExploreContainer);
