import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import LayoutContainer from './layout/layout-container.jsx';
import SSOLogin from './login/sso-login.jsx';
import ThirdPartyLogin from './login/third-party-login.jsx';

import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path='/login/sso/:ssoToken' exact component={SSOLogin} />
            <Route path='/login/third-party/:ssoToken' exact component={ThirdPartyLogin} />
            <Route path='/' component={LayoutContainer} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
