import _ from 'lodash';

const continentCodeMap = {
  'Africa': {
    code: '002',
    regions: [{
      code: '015',
      countries: 'DZ, EG, EH, LY, MA, SD, SS, TN'
    }, {
      code: '011',
      countries: 'BF, BJ, CI, CV, GH, GM, GN, GW, LR, ML, MR, NE, NG, SH, SL, SN, TG'
    }, {
      code: '017',
      countries: 'AO, CD, ZR, CF, CG, CM, GA, GQ, ST, TD'
    }, {
      code: '014',
      countries: 'BI, DJ, ER, ET, KE, KM, MG, MU, MW, MZ, RE, RW, SC, SO,' +
      ' TZ, UG, YT, ZM, ZW'
    }, {
      code: '018',
      countries: 'BW, LS, NA, SZ, ZA'
    }]
  },
  'Europe': {
    code: '150',
    regions: [{
      code: '154',
      countries: 'GG, JE, AX, DK, EE, FI, FO, GB, IE, IM, IS, LT, LV, NO, SE, SJ'
    }, {
      code: '155',
      countries: 'AT, BE, CH, DE, DD, FR, FX, LI, LU, MC, NL'
    }, {
      code: '151',
      countries: 'BG, BY, CZ, HU, MD, PL, RO, RU, SU, SK, UA'
    }, {
      code: '039',
      countries: 'AD, AL, BA, ES, GI, GR, HR, IT, ME, MK, MT, CS, RS, PT, SI, SM, VA, YU'
    }]
  },
  'America': {
    code: '019',
    regions: [{
      code: '021',
      countries: 'BM, CA, GL, PM, US'
    }, {
      code: '029',
      countries: 'AG, AI, AN, AW, BB, BL, BS, CU, DM, DO, GD, GP, HT, JM, KN, KY, LC, MF, MQ, MS, PR, TC, TT, VC, VG, VI'
    }, {
      code: '013',
      countries: 'BZ, CR, GT, HN, MX, NI, PA, SV'
    }, {
      code: '005',
      countries: 'AR, BO, BR, CL, CO, EC, FK, GF, GY, PE, PY, SR, UY, VE'
    }]
  },
  'Asia': {
    code: '142',
    regions: [{
      code: '143',
      countries: 'TM, TJ, KG, KZ, UZ'
    }, {
      code: '030',
      countries: 'CN, HK, JP, KP, KR, MN, MO, TW'
    }, {
      code: '034',
      countries: 'AF, BD, BT, IN, IR, LK, MV, NP, PK'
    }, {
      code: '035',
      countries: 'BN, ID, KH, LA, MM, BU, MY, PH, SG, TH, TL, TP, VN'
    }, {
      code: '145',
      countries: 'AE, AM, AZ, BH, CY, GE, IL, IQ, JO, KW, LB, OM, PS, QA, SA, NT, SY, TR, YE, YD'
    }]
  },
  'Oceania': {
    code: '009',
    regions: [{
      code: '053',
      countries: 'AU, NF, NZ'
    }, {
      code: '054',
      countries: 'FJ, NC, PG, SB, VU'
    }, {
      code: '057',
      countries: 'FM, GU, KI, MH, MP, NR, PW'
    }, {
      code: '061',
      countries: 'AS, CK, NU, PF, PN, TK, TO, TV, WF, WS'
    }]
  }
};

let finalCountryRegionContinentCodeMap = {};

/* eslint-disable-next-line */
for (const continent of Object.values(continentCodeMap)) {
    continent.regions.forEach(region => {
      region.countries.split(', ').forEach(country => {
        finalCountryRegionContinentCodeMap[country] = {regionCode: region.code, continentCode: continent.code};
      });
    });
}

finalCountryRegionContinentCodeMap['WO'] = {regionCode: '000', continentCode: '000'};

export default (countryCodeArray) => {
  let regionContinentCodeArray = [];
  countryCodeArray.forEach(countryCode => {
    regionContinentCodeArray.push(finalCountryRegionContinentCodeMap[countryCode]);
  });

  let regionArray = regionContinentCodeArray.map(obj => obj.regionCode);
  if (_.uniq(regionArray).length === 1) {
    return regionArray[0];
  } else {
    let continentArray = regionContinentCodeArray.map(obj => obj.continentCode);
    if (_.uniq(continentArray).length === 1) {
      return continentArray[0];
    } else {
      return 'world';
    }
  }
};
