import rivets from 'rivets';

import rivetsDeronFormatters from './rivetsDeronFormatters';
import {log} from '../log-utils';
import consoleLogger from '../log-utils/consoleLogger';

const binders = {
	fade(ele, value) {
		ele.style.opacity = value ? 0 : 1;
	},
};

const formatters = {
	shorttime(value) {
		if (isNaN(value)) {
			return '0:00';
		}
		let date = new Date(value * 1000);
		let padd = function(t) {
			if (t < 10)	return '0' + t;
			else		return t.toString();
		};
		if	( date.getUTCHours() > 0 ) {
			return padd(date.getUTCHours()) + ':'
        + padd(date.getUTCMinutes()) + ':'
        + padd(date.getUTCSeconds());
		} else {
			return padd(date.getUTCMinutes()) + ':' + padd(date.getUTCSeconds());
		}
	},
  // expect ranges in 10-30,70-100 format
  inrange(value, arg) {
    let ranges = arg.split(',');
    /* eslint-disable-next-line no-unused-vars */
    for (let range of ranges) {
      range = range.split('-');
      let min = parseFloat(range[0]);
      let max = parseFloat(range[1]);
      if (value >= min && value <= max) {
        return true;
      }
    }
    return false;
  },
};

export const configureRivets = (ele, mpmlObject, options = {}) => {
  rivets.configure({
    prefix: 'mpml',
    handler(target, event, binding) {
			let thisFn = this;
			if ((typeof thisFn).toLowerCase() !== 'function') {
        consoleLogger.error('function not defined');
				thisFn = () => {};
			}
      let args = target.getAttribute(`${event.type}-args`)
           || target.getAttribute(`mpml-${event.type}-args`);

      let argType = target.getAttribute(`mpml-${event.type}-args-type`);
      if (args && argType) {
        switch(argType) {
          case 'float':
            args = parseFloat(args);
            break;
          case 'integer':
            args = parseInt(args);
            break;
          default:
            args = args || false;
            break;
        }
      }

      // only do this if args is not there
      let xFractionEvents = ['seek', 'setVolume', 'setPlaybackRate'];
      if (!args && xFractionEvents.indexOf(binding.keypath) !== -1) {
        consoleLogger.log('seek function called. time for magic');
        let targetArea = target.getBoundingClientRect();
        let xPosition = event.pageX - (
          targetArea.left
            + document.body.scrollLeft
            + document.documentElement.scrollLeft
        );
        let totalWidth = targetArea.width;
        args = xPosition / totalWidth;
        if (binding.keypath === 'seek') {
          args = args * mpmlObject.duration;
        }
      }
      thisFn.call(mpmlObject, args);

			// log the event
			const eventTime = new Date();
			const logObj = {
				time: eventTime.getTime(),
				eventType: event.type,
				eventHandler: binding.keypath,
			};
      if (typeof options.userEventSubscriber === 'function') {
        options.userEventSubscriber({
          name: event.type,
          payload: args,
        });
      }
			log(logObj);
    },
  });

  rivets.binders = {
    ...rivets.binders,
    ...binders,
  };

  rivets.formatters = {
    ...rivetsDeronFormatters,
    ...rivets.formatters,
    ...formatters,
  };

  rivets.bind(ele, mpmlObject);
};
