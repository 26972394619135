import React from 'react';
import ReactAce from 'react-ace';
// eslint-disable-next-line
import brace from 'brace';

import 'brace/mode/html';
import 'brace/mode/sass';
import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/ext/searchbox';

const noop = () => {};

const AceEditor = ({
  editor,
  height,
  mode,
  markers,
  value,
  onChange,
  onBlur,
  onSave
}) => {
  markers = typeof markers !== 'undefined' ? markers : [];
  onBlur = typeof onBlur === 'function' ? onBlur : noop;
  onChange = typeof onChange === 'function' ? onChange : noop;
  return (
    <ReactAce
      ref={c => editor = c}
      style={{
        border: '1px solid #DEDEDE'
      }}
      width='100%'
      height={typeof height !== 'undefined' ? height : '640px'}
      mode={mode}
      theme='github'
      name={`${mode}-editor`}
      wrapEnabled={true}
      commands={[{
        name: 'saveHTMLAndSass',
        bindKey: {
          mac: 'Command-s',
          win: 'Ctrl-s'
        },
        exec: () => onSave()
      }]}
      markers={markers}
      tabSize={2}
      editorProps={{$blockScrolling: Infinity}}
      value={value}
      onBlur={() => onBlur(editor)}
      onChange={(newVal) => onChange(newVal, mode)} />
  );
};

export default AceEditor;
