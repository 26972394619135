import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';

import {saveTokenAPI, saveSSOTokenAPI} from './login-service';
import {getQueryParams} from '../utils/routing';

class ThirdPartyLogin extends React.Component {
  constructor (props) {
    super(props);
    const {search} = this.props.history.location;
    const {redirect} = getQueryParams(search);

    this.state = {
      redirect,
      shouldRedirect: false
    };
  }
  componentWillMount () {
    const {ssoToken} = this.props.match.params;
    const {accessToken} = JSON.parse(atob(ssoToken));
    saveSSOTokenAPI(ssoToken);
    saveTokenAPI(accessToken);

    this.setState({shouldRedirect: true});
  }
  render () {
    const {shouldRedirect, redirect} = this.state;
    return shouldRedirect ? <Redirect to={redirect} /> : <div />;
  }
}

export default withRouter(ThirdPartyLogin);
