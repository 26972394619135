import {injectHTML, injectResetCSS, injectCSS} from './markup-utils';

/**
 * setTheme function to create mock video player for preview
 * @param {HTMLDomElement} ele the container where theme will appendChild
 */
const setFullScreenCSS = (ele) => {
  const STYLEID = 'fullScreenStyle';
  let cssText = document.createTextNode(`.full-screen-player {
    width: 100% !important;
    height: 100% !important;
  }`);
  let styleEle = document.getElementById(STYLEID);
  if (!styleEle) {
    styleEle = document.createElement('style');
    styleEle.id = STYLEID;
    ele.insertBefore(styleEle, ele.childNodes[0]);
  }
  styleEle.innerHTML = '';
  styleEle.appendChild(cssText);
};

/**
 * setTheme function to create mock video player for preview
 * @param {Object} htmlObject
 * @param {String} htmlObject.html complete HTML as a string or a URL
 * @param {String} htmlObject.css complete CSS as a string or a URL
 * @param {HTMLDomElement} ele the container where theme will appendChild
 * @param {MPMLObject} mpmlObject derived from one of the media elements
 * @param {object} options
 * @return {{injectHTML:function}} to add HTML at runtime
 */
const setTheme = (htmlObject, ele, mpmlObject, options) => {
  // Get HTML and CSS from htmlObject
  const {html, css} = htmlObject;

  // Set position of ele to 'relative'
  // TODO explain why in comments
  ele.style.position = 'relative';

  // Inject full screen css
  setFullScreenCSS(ele);

  // Prepend the HTML inside the ele as first child
  // the fifth argument says to rewrite the theme if it already exist
  injectHTML(ele, html, mpmlObject, options, true);

  // Prepend the CSS inside the ele in a style tag as the new first child
  injectCSS(ele, css);

  // Prepend the Reset CSS
  injectResetCSS(ele);

  return {
    injectHTML(htmlString) {
      injectHTML(ele, htmlString, mpmlObject, options);
    },
  };
};

export default setTheme;
