import React from 'react';

import AceEditor from '../components/ace-editor.jsx';

let jsonEditor;
const MPMLParams = ({
  currentTab,
  mpmlObject,
  onChangeMPMLJSON
}) => {
  return (
    <AceEditor
      editor={jsonEditor}
      currentTab={currentTab}
      height='250px'
      mode='json'
      value={JSON.stringify(mpmlObject, null, 2)}
      onBlur={editor => onChangeMPMLJSON(editor)} />
  );
};

export default MPMLParams;
