/**
 * This utility file exports method to log and subscribe mpml events.This log &
 * subscribe only exposes user events such as click and the corresponding mpml
 * method which will be called. This is intended for theme developers. Theme
 * developers will listen in to these methods to test their themes either
 * programmatically or on the theplayer.io theme editor interface.
 *
 * This is not so useful for mpml library developers who are more interested in
 * the actions of core lib. Identification of players, loading assets are more
 * useful to library developers. If you are looking at this file, these are not
 * the droids you are looking for
 */


let listeners = [];

/**
 * subsribe to all mpml events
 * @param {function(time:number, eventType:string, eventHandler:string): void}
 * listener
 * @return {function(): void} unsubscribe function, calling the returned method
 *     will remove this listner from subscribers list
 */
export const subscribe = (listener) => {
  listeners.push(listener);

  return () => {
    listeners = listeners.filter((listnr) => listnr !== listener);
  };
};

/**
 * log an mpml event
 * automatically called by the internal handler function
 *
 * @param {Object} logObj
 * @param {number} logObj.time milliseconds since epoch
 * @param {string} logObj.eventType e.g. click, mouseover etc.
 * @param {string} logObj.eventHandler player method which will be called
 */
export const log = (logObj) => {
  listeners.forEach((listener) => listener(logObj));
};
