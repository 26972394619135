import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.jsx';
import './css/index.css';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./App.jsx', () => {
    const NewApp = require('./App.jsx').default;
    ReactDOM.render(
      <NewApp />,
      document.getElementById('root')
    );
  });
}
