import CustomLineChart from './base-classes/custom-line-chart.jsx';
import {getTimeDuration} from './vis-utils';
import {fetchLoadCountAPI} from '../analytics-service';

class LoadCount extends CustomLineChart {
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchLoadCount(activeQuickDuration);
  }
  fetchLoadCount (duration, filter) {
    let time = getTimeDuration(duration);
    fetchLoadCountAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        let lineData = [];
        let fields = [`${filter === 'load' ? 'Load' : 'View'} Count`];
        buckets.forEach(bucket => {
          let [date, timeString] = bucket.key_as_string.split('T');
          let time = timeString.split(':').slice(0, 2).join(':');
          lineData.push({
            key: `${date} ${time}`,
            [fields[0]]: bucket.doc_count
          });
        });
        window.setTimeout(() => this.setState({
          lineData,
          fields
        }), 100);
      })
      .catch(console.log);
  }
  componentWillReceiveProps (nextProps) {
    let {parent, activeQuickDuration, filter} = nextProps;
    this.setState({
      width: parent.offsetWidth,
      left: 0.05 * parent.offsetWidth,
      right: 0.05 * parent.offsetWidth
    });
    if (this.props.activeQuickDuration !== activeQuickDuration || this.props.filter !== filter) {
      this.fetchLoadCount(activeQuickDuration, filter);
    }
  }
}

export default LoadCount;
