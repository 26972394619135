import CustomLineChart from './base-classes/custom-line-chart.jsx';
import {getTimeDuration} from './vis-utils';
import {fetchDateHistogramAPI} from '../analytics-service';

class DateHistogram extends CustomLineChart {
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchDateHistogram(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {parent, activeQuickDuration} = nextProps;
    this.setState({
      width: parent.offsetWidth,
      left: 0.05 * parent.offsetWidth,
      right: 0.05 * parent.offsetWidth
    });
    if (this.props.activeQuickDuration !== activeQuickDuration) {
      this.fetchDateHistogram(activeQuickDuration);
    }
  }
  fetchDateHistogram (duration) {
    let time = getTimeDuration(duration);
    fetchDateHistogramAPI(time)
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        let lineData = [];
        let fields = ['Total Covered', 'Total Played'];
        buckets.forEach(bucket => {
          let [date, timeString] = bucket.key_as_string.split('T');
          let time = timeString.split(':').slice(0, 2).join(':');
          lineData.push({
            key: `${date} ${time}`,
            'Total Covered': parseFloat((bucket[1].value / 60).toFixed(2)), // in minutes
            'Total Played': parseFloat((bucket[3].value / 60).toFixed(2)) // in minutes
          });
        });
        let label = 'in minutes';
        let maxTick = lineData.reduce((acc, curr) => {
          let maxForCurrent = curr['Total Covered'] > curr['Total Played'] ?
            curr['Total Covered'] :
            curr['Total Played'];
          return acc > maxForCurrent ? acc : maxForCurrent;
        }, 0);
        if (maxTick > 24 * 60) {
          label = 'in hours';
          lineData = lineData.map(data => ({
            ...data,
            'Total Covered': parseFloat((data['Total Covered'] / 60).toFixed(2)),
            'Total Played': parseFloat((data['Total Played'] / 60).toFixed(2))
          }));
        }
        window.setTimeout(() => this.setState({
          lineData,
          fields,
          label
        }), 100);
      })
      .catch(console.log);
  }
}

export default DateHistogram;
