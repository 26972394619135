/* eslint-disable */
export default {
  '!'(value) {
    return !value;
  },
  eq(value, args) {
    return value === args;
  },
  neq(value, args) {
    return value !== args;
  },
  gt(value, args) {
    return value > args;
  },
  gte(value, args) {
    return value >= args;
  },
  lt(value, args) {
    return value < args;
  },
  lte(value, args) {
    return value <= args;
  },
  or(value, args) {
    return value || args;
  },
  and(value, args) {
    return value && args;
  },
  isEmpty(value) {
    return (typeof value === 'undefined'
      || value === null
      || (typeof value === 'string' && value.length === 0));
  },
  isNotEmpty(value) {
    return !isEmpty(value);
  },

  pass(value, args) {
    return args;
  },

  json(value, intendation) {
    return JSON.stringify(value, null, intendation || 0);
  },

  prefix(value, prefix) {
    return '' + prefix + value;
  },

  suffix(value, suffix) {
    return '' + value + suffix;
  },

  ucFirst(value) {
    return value.substr(0, 1).toUpperCase() + value.substr(1);
  },

  '+'(value, args) {
    return value + args;
  },

  '-'(value, args) {
    return value - args;
  },

  '*'(value, args) {
    return value * args;
  },

  '/'(value, args) {
    return value / args;
  },

  round(value, decimals) {
    if (decimals) {
      let exp = Math.pow(10, decimals);
      value = Math.round(value * exp) / exp;
    } else {
      value = Math.round(value);
    }

    return value;
  },

  get(obj, key) {
    if (obj && typeof obj === 'object') {
      return obj[key];
    }
    return null;
  },

  set(obj, key, value) {
    if (obj && typeof obj === 'object') {
      obj[key] = value;
    }

    return obj;
  },

  '.'(obj, key) {
    return this.get(obj, key);
  },

  keys(obj) {
    if (typeof obj === 'object') {
      return Object.keys(obj);
    }

    return [];
  },

  length(value) {
    return value ? (value.length || 0) : 0;
  },

  /*
   * value[, by][, direction]
   */
  sort(value, by, direction) {
    return value;

    if (!direction && (by == 'asc' || by == 'desc')) {
      direction = by;
      by = null;
    }

    if (!by) {
      value.sort();
    } else {
      value.sort(function(a, b) {
        if (a[by] === b[by]) return 0;

        return (a[by] < b[by]) ? -1 : 1;
      });
    }

    if (direction == 'desc') {
      value.reverse();
    }

    return value;
  },

  default(value, args) {
    return (typeof value !== 'undefined' && value !== null) ? value : args;
  },

  contains(value, search) {
    if (Array.isArray(value)) {
      return (value.indexOf(search) !== -1);
    }

    return false;
  },

  percent(value, decimals) {
    return number_format(value * 100, decimals || 0, ',') + '%';
  },

  /*
   * fn, thisArg[, arg1, arg2, ..., argN]
   */
  bind(fn, self, ...args) {
    if (typeof fn === 'function') {
      return function() {
        fn.apply(self, args);
      };
    }

    return fn;
  },

  /*
   * fn, arg1, arg2, ..., argN
   */
  with(fn, ...args) {
    if (typeof fn === 'function') {
      return fn.bind(null, args);
    }

    return fn;
  },

  slice(arr, ...args) {
    return Array.prototype.slice.apply(arr, args);
  },
};
