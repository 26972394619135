import axios from 'axios';

import {authRequest} from '../utils/api';

export function fetchVitalsAPI (data) {
  return authRequest({
    url: '/analytics/vitals',
    method: 'POST',
    data
  });
}

export function fetchCitiesAPI (data) {
  return authRequest({
    url: '/analytics/cities',
    method: 'POST',
    data
  });
}

export function fetchBrowsersAPI (data) {
  return authRequest({
    url: '/analytics/browsers',
    method: 'POST',
    data
  });
}

export function fetchLastStateAPI (data) {
  return authRequest({
    url: '/analytics/last-state',
    method: 'POST',
    data
  });
}

export function fetchOSDeviceDistAPI (data) {
  return authRequest({
    url: '/analytics/os-device-dist',
    method: 'POST',
    data
  });
}

export function fetchDateHistogramAPI (time, videoId) {
  return authRequest({
    url: '/analytics/date-histogram',
    method: 'POST',
    data: {
      time,
      videoId
    }
  });
}

export function fetchLoadCountAPI (data) {
  return authRequest({
    url: '/analytics/load-count',
    method: 'POST',
    data
  });
}

export function fetchCitiesTableAPI (data) {
  return authRequest({
    url: '/analytics/cities-table',
    method: 'POST',
    data
  });
}

export function fetchPopularVideosAPI (data) {
  return authRequest({
    url: '/analytics/popular-videos',
    method: 'POST',
    data
  });
}

export function fetchRealTimeMarkersAPI () {
  return authRequest({
    url: '/analytics/real-time',
    method: 'GET'
  });
}

export function fetchVideoAnalyticsAPI (data) {
  let apis = [fetchVitalsAPI, fetchCitiesAPI, fetchBrowsersAPI, fetchOSDeviceDistAPI, fetchLastStateAPI, fetchDateHistogramAPI, fetchLoadCountAPI];
  return axios.all(apis.map(api => api(data)));
}

export function queryPopularVideosAPI (time, query) {
  return authRequest({
    url: '/analytics/query-popular-videos',
    method: 'POST',
    data: {
      time,
      query
    }
  });
}
