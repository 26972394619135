export default [
  {
    label: '1 month',
    duration: '1m'
  }, {
    label: '3 months',
    duration: '3m'
  }, {
    label: '6 months',
    duration: '6m'
  }, {
    label: '3 days',
    duration: '3d'
  }, {
    label: 'week',
    duration: '1w'
  }, {
    label: '2 weeks',
    duration: '2w'
  }, {
    label: '4 hours',
    duration: '4h'
  }, {
    label: '12 hours',
    duration: '12h'
  }, {
    label: '1 day',
    duration: '1d'
  }
];
