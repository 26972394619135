export const getQueryParams = (query) => {
  if (!query) {
    return {};
  }
  let queryParams = {};
  let querySplit = query.slice(1).split('&');
  querySplit.forEach((val) => {
    let valSplit = val.split('=');
    queryParams[valSplit[0]] = valSplit[1];
  });

  return queryParams;
};
