import React, {Component} from 'react';

import {getTimeDuration} from './vis-utils';
import {fetchCitiesTableAPI} from '../analytics-service';

class CitiesTable extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      topCitiesList: []
    };
  }
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchCitiesTable(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration, filter} = nextProps;
    if (this.props.activeQuickDuration !== activeQuickDuration || this.props.filter !== filter) {
      this.fetchCitiesTable(activeQuickDuration, filter);
    }
  }
  fetchCitiesTable (duration, filter) {
    let time = getTimeDuration(duration);
    fetchCitiesTableAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        let topCitiesList = [];
        buckets.forEach(bucket => {
          let bucketCities = bucket[3].buckets.map(city => ({
            city: city.key,
            value: city.doc_count,
            country: bucket.key
          }));
          topCitiesList = [
            ...topCitiesList,
            ...bucketCities
          ];
        });
        topCitiesList = topCitiesList.sort((cityA, cityB) => cityB.value - cityA.value).slice(0, 10);
        this.setState({
          topCitiesList
        });
      })
      .catch(console.log);
  }
  render () {
    let {topCitiesList} = this.state;
    let {filter} = this.props;
    return (
      <table className='vc-analytics__table'>
        <thead>
          <tr>
            <th>City</th>
            <th>Country</th>
            <th>{filter === 'load' ? 'Load' : 'View'} Count</th>
          </tr>
        </thead>
        <tbody>
          {topCitiesList.map((city, index) => <tr key={index}>
            <td>{city.city}</td>
            <td>{city.country}</td>
            <td>{city.value}</td>
          </tr>)}
        </tbody>
      </table>
    );
  }
}

export default CitiesTable;
