import React from 'react';

import AceEditor from './ace-editor.jsx';
import SassParamsEditor from './sass-params-editor.jsx';

let htmlEditor, sassEditor;
const ParamsEditor = ({
  currentTab,
  markers,
  html,
  sass,
  sassValues,
  sassError,
  mpmlObject,
  colorPickerShown,
  showColorPicker,
  hideColorPicker,
  onSave,
  onMarkupTabClick,
  onChangeMarkup,
  onChangeSassValueType,
  onChangeSassValues
}) => {
  return (
    <div>
      <div className='vc-app__tabs'>
        <span
          className={`vc-app__tab${currentTab === 'sassParams' ? ' active' : ''}`}
          onClick={e => onMarkupTabClick('sassParams')}>
          SASS Params
        </span>
        <span
          className={`vc-app__tab${currentTab === 'html' ? ' active' : ''}`}
          onClick={e => onMarkupTabClick('html')}>
          HTML
        </span>
        <span
          className={`vc-app__tab${currentTab === 'sass' ? ' active' : ''}`}
          onClick={e => onMarkupTabClick('sass')}>
          SASS
          {sassError.length > 0 && <span className='sass-error' title={sassError}>
            <i className='material-icons'>error</i>
          </span>}
        </span>
      </div>
      {currentTab === 'sassParams' && <SassParamsEditor colorPickerShown={colorPickerShown} showColorPicker={() => showColorPicker()} hideColorPicker={() => hideColorPicker()} sassValues={sassValues} onChangeSassValues={(newVal, line) => onChangeSassValues(newVal, line)} onChangeSassValueType={(type, key) => onChangeSassValueType(type, key)} />}
      {currentTab === 'html' && <AceEditor editor={htmlEditor} mode='html' value={html} markers={markers} onSave={() => onSave()} onChange={(newVal, mode) => onChangeMarkup(newVal, mode)} />}
      {currentTab === 'sass' && <AceEditor editor={sassEditor} mode='sass' value={sass} onSave={() => onSave()} onChange={(newVal, mode) => onChangeMarkup(newVal, mode)} />}
    </div>
  );
};

export default ParamsEditor;
