import React from 'react';

const Loader = ({
  color,
  size,
  style
}) => {
  return (
    <div style={style} className={`preloader-wrapper ${size} active`}>
      <div className={`spinner-layer spinner-${color}-only`}>
        <div className="circle-clipper left">
          <div className="circle" />
        </div>
        <div className="gap-patch">
          <div className="circle" />
        </div>
        <div className="circle-clipper right">
          <div className="circle" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
