import React from 'react';

import Loader from '../generic/loader/index.jsx';
import CONFIG from '../utils/config';

import './explore.css';

const config = CONFIG(process.env.REACT_APP_API_ENV);

const THEME_BUCKET = config.S3_BUCKET;
const THEME_PREFIX = config.S3_PREFIX;

const Explore = ({
  isLoggedIn,
  isForking,
  communityThemes,
  onEditButtonClick
}) => {
  return (
    <div className='vc-app__container'>
      <h1 className='vc-helpers__center'>Community Themes</h1>
      {communityThemes.map((theme, index) => (
        <div className='mdc-layout-grid' key={index}>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
              <div className='vc-image__container'>
                <img className='vc-image--responsive' src={`https://s3.amazonaws.com/${THEME_BUCKET}/${THEME_PREFIX}/${theme.id}.png`} alt={`theme ${theme.name}`} />
              </div>
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
              <div className='vc-helpers__right'>
                {isForking && <Loader size='small' color='blue' style={{verticalAlign: 'bottom', marginRight: 10}} />}
                <button disabled={isForking} className='mdc-button mdc-button--raised' onClick={() => onEditButtonClick(theme.id)}>
                  {isLoggedIn ? 'Use & Edit' : 'Login to use'}
                </button>
              </div>
              <div className='vc-theme__name'>{theme.name}</div>
              <div className='vc-theme__date-container'>
                <div className='vc-theme__date'>Created: <span>{theme.createdAt}</span></div>
                <div className='vc-theme__date'>Last Updated: <span>{theme.updatedAt}</span></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Explore;
