import React, {Component} from 'react';

import {fetchRealTimeMarkersAPI} from './analytics-service';
import {
  formatDuration, getColorScheme, getPlaybackArray, getRegion,
  loadScript
} from '../utils/markup';

import './realtime-analytics.css';

import android from './icons/android.png';
import chrome from './icons/chrome.png';
import chromium from './icons/chromium.png';
import firefox from './icons/firefox.png';
import linux from './icons/linux.png';
import macos from './icons/macos.png';
import opera from './icons/opera.png';
import safari from './icons/safari.png';
import ubuntu from './icons/ubuntu.png';
import ucbrowser from './icons/ucbrowser.png';
import windows from './icons/windows.png';
import blank from './icons/blank.gif';

const iconsMap = {
  android,
  chrome,
  chromium,
  firefox,
  linux,
  macos,
  opera,
  safari,
  ubuntu,
  ucbrowser,
  windows
};

const lastStateMap = {
  0: {
    icon: 'play_circle_filled',
    color: '#4A4A4A',
    title: 'Ready'
  },
  1: {
    icon: 'play_arrow',
    color: '#4CAF50',
    title: 'Playing'
  },
  2: {
    icon: 'pause',
    color: '#4A4A4A',
    title: 'Paused'
  },
  3: {
    icon: 'stop',
    color: '#F44336',
    title: 'Ended'
  }
};
const CANVAS_HEIGHT = 45;
const MAX_STICKS = 200;
class RealtimeAnalytics extends Component {
  constructor (props) {
    super(props);

    this.state = {
      playbackData: [],
      chart: null,
      canvasDimensionsSet: false,
      listOfPlaybackArrays: []
    };
  }
  componentDidMount () {
    loadScript('https://www.gstatic.com/charts/loader.js', 'vc-realtime-map')
      .then(() => this.setGoogleCharts())
      .then(() => this.setDataFetchInterval());
  }
  componentWillUnmount () {
    window.clearInterval(this.dataFetchInterval);
  }
  setGoogleCharts () {
    return new Promise(resolve => {
      window.google.charts.load('current', {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyAGXxmvc0IUxRJYV14r-tjp2uEM4qTPsbQ'
      });
      window.google.charts.setOnLoadCallback(() => this.setMapAndPlaybackCanvas());
      resolve();
    });
  }
  setDataFetchInterval () {
    return new Promise(resolve => {
      this.dataFetchInterval = window.setInterval(() => this.setMapAndPlaybackCanvas(), 15 * 1000);
      resolve();
    });
  }
  setMapAndPlaybackCanvas () {
    return fetchRealTimeMarkersAPI()
      .then(res => this.setPlaybackData(res.data))
      .then(data => this.setMarkersData(data))
      .then(dataMap => this.drawMarkersMap(dataMap))
      .then(() => this.setPlaybackCanvasDimensions())
      .then(() => this.drawPlaybackCanvasForItems());
  }
  setPlaybackData (data) {
    return new Promise(resolve => {
      let listOfPlaybackArrays = [];
      data = data.filter(item => item.media && item.media.duration);
      data = data.filter(item => item.media && item.media.title);
      data.forEach(item => {
        let playbackArray = getPlaybackArray(item.beats, item.media.duration, MAX_STICKS);
        listOfPlaybackArrays.push(playbackArray);
      });
      this.setState({
        playbackData: data.map(item => {
          let finalCanvasIndex = item.beats.length > 0 ? Math.floor(item.beats.slice(-1)[0].pt * MAX_STICKS / item.media.duration) : 0;
          if (!item.media) {
            item.media = {
              duration: 0,
              title: 'Unknown',
            };
          }
          return {
            ...item,
            finalCanvasIndex
          };
        }),
        listOfPlaybackArrays
      });
      resolve(data);
    });
  }
  setMarkersData (data) {
    return new Promise(resolve => {
      let markers = [];
      data.forEach(location => {
        let {city, country} = location.geolocation;
        let locationMarker = city ? city + ', ' + country : country;
        let locationMarkerIndex = markers.findIndex(item => item[0] === locationMarker);
        if (locationMarkerIndex > -1) {
          let locationArrayItem = markers[locationMarkerIndex];
          locationArrayItem[1] += 1;
        } else {
          markers.push([locationMarker, 1]);
        }
      });
      markers = markers.sort((item1, item2) => item1[1] < item2[1]);
      markers.unshift(['City', 'Sessions']);
      resolve({
        markers,
        data
      });
    });
  }
  drawMarkersMap ({markers, data}) {
    return new Promise(resolve => {
      let markerData = window.google.visualization.arrayToDataTable(markers);

      let options = {
        region: getRegion(data.map(item => item.geolocation)),
        displayMode: 'markers',
        colorAxis: {colors: ['green', 'orange', 'red']}
      };
      let {chart} = this.state;
      if (!chart) {
        chart = new window.google.visualization.GeoChart(document.getElementById('regions-div'));
        this.setState({
          chart
        });
      }

      chart.draw(markerData, options);
      resolve();
    });
  }
  setPlaybackCanvasDimensions () {
    return new Promise(resolve => {
      let dimensionInterval = window.setInterval(() => {
        if (!this.state.canvasDimensionsSet) {
          let playbackContainer = document.querySelector('.vc-analytics-realtime__playback-info');
          if (playbackContainer) {
            let canvasContainerDimensions = document.querySelector('.vc-analytics-realtime__playback-info').getBoundingClientRect();
            let {width} = canvasContainerDimensions;
            this.setState({
              canvasWidth: width,
              canvasDimensionsSet: true
            });
            window.clearInterval(dimensionInterval);
            resolve();
          }
        } else {
          window.clearInterval(dimensionInterval);
          resolve();
        }
      }, 250);
    });
  }
  drawPlaybackCanvasForItems () {
    let {listOfPlaybackArrays, canvasWidth} = this.state;
    let stickWidth = canvasWidth / MAX_STICKS;
    let maxPlaybackValue = listOfPlaybackArrays.reduce((acc, curr) => {
      let currentMax = curr.reduce((acc2, curr2) => curr2 > acc2 ? curr2 : acc2, 0);
      return currentMax > acc ? currentMax : acc;
    }, 0);
    listOfPlaybackArrays.forEach((playbackArray, index) => this.drawCanvasForItem(playbackArray, index, canvasWidth, stickWidth, maxPlaybackValue));
  }
  drawCanvasForItem (playbackArray, index, canvasWidth, stickWidth, maxPlaybackValue) {
    let {playbackData} = this.state;
    if (document.querySelectorAll('.vc-analytics-realtime__playback-info')[index]) {
      let canvas = document.querySelectorAll('.vc-analytics-realtime__playback-info')[index].querySelector('canvas');
      let context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (playbackData[index].beats.length > 0) {
        context.fillStyle = '#DEDEDE';
        let finalIndex = Math.floor(playbackData[index].beats.slice(-1)[0].bl * MAX_STICKS / playbackData[index].media.duration);
        for (let i = 0; i < finalIndex; i++) {
          let upperLeftX = ((i + 1) * stickWidth);
          let upperLeftY = 0;
          context.fillRect(upperLeftX, upperLeftY, stickWidth, CANVAS_HEIGHT);
        }
      }
      playbackArray.forEach((playbackValue, playbackIndex) => {
        if (playbackValue === 0) {
          return;
        }
        let colorScheme = getColorScheme(playbackValue / maxPlaybackValue);
        context.fillStyle = `rgb(${colorScheme[0]}, ${colorScheme[1]}, ${colorScheme[2]})`;
        let upperLeftX = (playbackIndex * stickWidth);
        let upperLeftY = 0;
        context.fillRect(upperLeftX, upperLeftY, stickWidth, CANVAS_HEIGHT);
      });
      if (playbackData[index].beats.length > 0) {
        let {finalCanvasIndex} = playbackData[index];
        context.strokeStyle = 'blue';
        context.strokeRect(finalCanvasIndex * stickWidth, 0, stickWidth, CANVAS_HEIGHT);
      }
    }
  }
  render () {
    let {playbackData, canvasWidth} = this.state;
    return <div className='vc-analytics-realtime__container'>
      <div className='vc-analytics-realtime__map-view' id='regions-div' />
      {playbackData.map((dataItem, index) => {
               let stateId = dataItem.beats.slice(-1)[0].st;
               let stateTitle = lastStateMap[stateId]? lastStateMap[stateId].title : "unknown title";
               let stateColor = lastStateMap[stateId]? lastStateMap[stateId].color : "#666666";
               let stateIcon = lastStateMap[stateId]? lastStateMap[stateId].icon : "stop";
               let browserName = (dataItem && dataItem.userInfo && dataItem.userInfo.browser) ? dataItem.userInfo.browser.name : 'unknownBrowser';
               let osName = (dataItem && dataItem.userInfo && dataItem.userInfo.os) ? dataItem.userInfo.os.name : 'unknownOs';
             return <div key={index} className='vc-analytics-realtime__session-container'>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
              <div className='vc-analytics-realtime__media-info'>
                <div className='vc-analytics-realtime__session-title'>{(dataItem.media) ? dataItem.media.title : 'unknown title'}</div>
                <div className='vc-analytics-realtime__session-location'>
                  {dataItem.geolocation.city ? `${dataItem.geolocation.city}, ` : ''}{dataItem.geolocation.country}
                </div>
                <div>
                  <img src={blank} className={`flag flag-${(dataItem.geolocation.countryCode || 'IN').toLowerCase()}`} alt={dataItem.geolocation.country} title={dataItem.geolocation.country} />
                </div>
                <div className='vc-analytics-realtime__session-ip'>{dataItem.userInfo.ip}</div>
              </div>
            </div>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
              <div className='vc-analytics-realtime__playback-info'>
                <canvas width={canvasWidth} height={CANVAS_HEIGHT} />
                <div className='vc-analytics-realtime__session-duration'>
                 {
                         dataItem.beats.length > 0 ? formatDuration(Math.floor(dataItem.beats.slice(-1)[0].pt)) : '--'} / {formatDuration(dataItem.media.duration)} {dataItem.beats.length > 0 ? <i title={stateTitle} style={{color: stateColor}} className='material-icons'>{stateIcon}</i> : ''}</div>
                <div className='vc-analytics-realtime__session-browser-os vc-helpers__right'>
                  <img className='vc__icons' src={iconsMap[browserName.replace(/ /g, '').toLowerCase()]} alt={browserName} />
                  <img className='vc__icons' src={iconsMap[osName.replace(/ /g, '').toLowerCase()]} alt={osName} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      })}
    </div>;
  }
}

export default RealtimeAnalytics;
