import React from 'react';
import {MDCSelect} from '@material/select';

import OverviewAnalytics from './overview-analytics.jsx';
import RealtimeAnalytics from './realtime-analytics.jsx';
import VideosAnalytics from './videos-analytics.jsx';

import './analytics.css';

const views = ['overview', 'videos'];
// const views = ['overview', 'realtime', 'videos'];
class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.views = {};
    this.state = {
      view: ''
    };
  }
  componentDidMount () {
    let {viewId} = this.props.match.params;
    let {history} = this.props;
    const viewSelect = new MDCSelect(document.querySelector('.mdc-select'));
    viewSelect.listen('MDCSelect:change', () => {
      let view = viewSelect.value;
      this.setState({
        view
      }, () => history.push(`/analytics/${view}`));
    });
    window.setTimeout(() => {
      this.viewSelectSurface.click();
      this.views[views.indexOf(viewId)].click();
      document.querySelector('.vc-analytics__container').click();
      this.setState({
        view: viewId
      });
    }, 250);
  }
  render() {
    let {view} = this.state;
    return (<div className='vc-app__container'>
      <div className='vc-analytics__container'>
        <div className='vc-analytics__view-select-container'>
          <div className='mdc-select' role='listbox'>
            <div className="mdc-select__surface" tabIndex={0} ref={c => this.viewSelectSurface = c}>
              <div className="mdc-select__label">Select a view here</div>
              <div className="mdc-select__selected-text"></div>
              <div className="mdc-select__bottom-line"></div>
            </div>
            <div className="mdc-simple-menu mdc-select__menu">
              <ul className="mdc-list mdc-simple-menu__items">
                {views.map((viewItem, index) => <li
                  ref={c => this.views[index] = c}
                  id={viewItem}
                  className='mdc-list-item'
                  role='option'
                  aria-selected={viewItem === view}
                  tabIndex={0}
                  key={index}>
                  {viewItem.toUpperCase()}
                </li>)}
              </ul>
            </div>
          </div>
        </div>
        {view === 'overview' && <OverviewAnalytics />}
        {view === 'realtime' && <RealtimeAnalytics />}
        {view === 'videos' && <VideosAnalytics />}
      </div>
    </div>);
  }
}

export default Analytics;
