import React, {Component} from 'react';
import quickIntervals from "./quick-intervals";

class FilterContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeQuickDuration: '2w',
      filterOpen: false
    };
  }
  toggleFilterOpenState () {
    let {filterOpen} = this.state;
    filterOpen = !filterOpen;
    this.setState({
      filterOpen
    });
  }
  setQuickInterval(interval) {
    this.setState({
      activeQuickDuration: interval.duration
    }, () => this.props.onSetQuickInterval(interval));
  }
  render () {
    let {activeQuickDuration, filterOpen} = this.state;
    let filterInterval = quickIntervals.find(interval => interval.duration === activeQuickDuration);
    let columns = [quickIntervals.slice(0, 3), quickIntervals.slice(3, 6), quickIntervals.slice(6)];
    return <div className='vc-analytics__filter-container vc-helpers__right'>
      <div className='vc-analytics__filter-label vc-helpers__right' onClick={() => this.toggleFilterOpenState()}>Filter: Last {filterInterval.label || ''}</div>
      <div className={`vc-analytics__filter-options${filterOpen ? ' vc-analytics__filter--open': ''}`}>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            {
              columns.map((column, gridIndex) => <div key={gridIndex} className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                {
                  column.map((interval, intervalIndex) => <div key={intervalIndex} onClick={() => this.setQuickInterval(interval)} className={`vc-analytics__quick-filter-item vc-helpers__center${activeQuickDuration === interval.duration
                    ? ' active'
                    : ''}`}>Last {interval.label}</div>)
                }
              </div>)
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

export default FilterContainer;
