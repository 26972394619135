import CustomPieChart from './base-classes/custom-pie-chart.jsx';
import {getTimeDuration} from './vis-utils';
import {fetchOSDeviceDistAPI} from '../analytics-service';

class OSDeviceDistribution extends CustomPieChart {
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchOSDeviceDist(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration, filter} = nextProps;
    
    if (this.props.activeQuickDuration !== activeQuickDuration || this.props.filter !== filter) {
      this.setState({
        fetchingData: true
      });
      this.fetchOSDeviceDist(activeQuickDuration, filter);
    }
  }
  fetchOSDeviceDist (duration, filter) {
    let time = getTimeDuration(duration);
    fetchOSDeviceDistAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        this.setState({
          buckets,
          fetchingData: false
        });
      })
      .catch(console.log);
  }
}

export default OSDeviceDistribution;
