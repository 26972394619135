import React from 'react';
import {Link} from 'react-router-dom';

import {getAccessToken} from '../utils/auth';

import './home.css';

const Home = () => {
  const isLoggedIn = getAccessToken();
  return (
    <div className='vc-container'>
      <div className='vc-jumbotron'>
        <div className='vc-jumbotron__image' />
        <div className='vc-jumbotron__text'>
          Rich customizable player experience for your videos
          <Link to={isLoggedIn ? '/analytics' : '/login'} className='mdc-button mdc-button--raised'>Get Started</Link>
        </div>
      </div>
      {/*<div className='vc-features'>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--flagship-themes' />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Flagship Themes</div>
                  <div className='vc-feature__text--description'>We have a lot of community contributed themes that gives you a refreshing experience with a single click.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Any Streaming Platform</div>
                  <div className='vc-feature__text--description'>Our themes are compatible with any streaming platform such as YouTube, Vimeo and others.</div>
                </div>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--streaming' />
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--customizable-experience' />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Customizable Experience</div>
                  <div className='vc-feature__text--description'>You can rewrite, tweak, remove or add anything from and to the theme with our amazing Theme Editor.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Native HTML5</div>
                  <div className='vc-feature__text--description'>All our themes use HTML5 and CSS3 to get the slick, agile and native feel.</div>
                </div>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--native-html5' />
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--usage-tracking' />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Usage Tracking</div>
                  <div className='vc-feature__text--description'>We have tracking enabled inside the theme without any overhead, you won't even notice its there.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8' />
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>...and lots more.</div>
                  <Link to='/login' className='mdc-button mdc-button--raised'>Get Started</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default Home;
