import React from 'react';

import Layout from './layout.jsx';
import {fetchAuthValidityInfo} from './layout-service';
import {getAccessToken, getUserInfo, removeAuthentication} from '../utils/auth';

class LayoutContainer extends React.Component {
  constructor (props) {
    super(props);

    const accessToken = getAccessToken();
    this.state = {
      email: '',
      isLoggedIn: !!accessToken
    };
  }
  componentWillMount () {
    const accessToken = getAccessToken();
    if (!!accessToken) {
      fetchAuthValidityInfo()
        .then(res => {
          if (res.status === 200) {
            const {email} = getUserInfo();
            const isLoggedIn = !!accessToken;
            this.setState({
              email,
              isLoggedIn
            });
          }
        })
        .catch(err => {
          if (err.status === 401) {
            this.onLogout();
          }
        });
    }
  }
  googleLogout () {
    let gapi = window.gapi || {};
    if (typeof gapi.auth2 === 'undefined' || typeof gapi.auth2.getAuthInstance === 'undefined') {
      return;
    }
    let auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut();
  }
  initiateThirdPartyLogout (thirdParty) {
    switch (thirdParty) {
      case 'google':
        this.googleLogout();
        break;
      default:
        return;
    }
  }
  onLogout () {
    const {loginParty} = getUserInfo();
    this.initiateThirdPartyLogout(loginParty);
    removeAuthentication();
    this.props.history.push('/');
    this.setState({
      email: '',
      isLoggedIn: false
    });
  }
  render () {
    const {email, isLoggedIn} = this.state;
    return <Layout email={email} isLoggedIn={isLoggedIn} onLogout={() => this.onLogout()} />
  }
}

export default LayoutContainer;
