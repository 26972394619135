import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import ThemesLanding from "./themes-landing.jsx";
import ExploreContainer from "../explore/explore-container.jsx";
import ThemesList from '../themes-list/themes-list.jsx';
import {getAccessToken} from "../utils/auth";

const Themes = ({
  onLogout
}) => {
  const isLoggedIn = getAccessToken();
  return <div className='vc-app__container'>
    <Route path='/themes' exact component={ThemesLanding} />
    <Route path='/themes/explore' component={ExploreContainer} />
    <Route path='/themes/my' render={() => isLoggedIn ? <ThemesList onLogout={() => onLogout()} /> : <Redirect to='/' />} />
  </div>;
};

export default Themes;
