/**
 * Logging levels in winston conform to the severity ordering specified by
 * RFC5424: severity of all levels is assumed to be numerically ascending from
 * most important to least important.
 */

let maps = {
  error: 'error',
  warn: 'warn',
  info: 'info',
  debug: 'debug',
  verbose: 'debug',
  noisy: 'debug',
};

let logger = {
  levels: {
    NONE: 0,
    ERROR: 1,
    WARNING: 2,
    INFO: 3,
    DEBUG: 4,
    VERBOSE: 5,
    NOISY: 6,
  },

  prefix: 'MPML',

  getLevel() {
    return Object.keys(this.levels).find((k) => this.levels[k] === this.level);
  },

  setLevel(levelName) {
    if (
      !levelName
        || !window.console
        || !this.levels[levelName.toUpperCase()]
    ) {
      console.error('logger could not set level to ', levelName);
      return;
    }

    levelName = levelName.toUpperCase();

    if (!this.levels[levelName]) {
      return this.error(`[${this.prefix}] Invalid logger level`);
    }

    this.level = this.levels[levelName];

    let nop = () => {};
    Object.keys(maps).forEach((k) => {
      this[k] = nop;
      if (
        console[maps[k]]
          && this.level >= this.levels[k.toUpperCase()]
      ) {
        this[k] = console[maps[k]].bind(console);
      }
    });

    this.log = this.info;
  },
};

logger.setLevel('ERROR');

export default logger;
