import {authRequest} from '../utils/api';

export const fetchUserThemes = () => {
  return authRequest({
    url: '/themes',
    method: 'GET'
  });
};

export const createNewThemeAPI = (name) => {
  return authRequest({
    url: '/themes',
    method: 'POST',
    data: {
      name
    }
  });
};

export const deleteThemeAPI = (themeId) => {
  return authRequest({
    url: `/themes/${themeId}`,
    method: 'DELETE'
  });
};
