import {authRequest} from '../utils/api';

export const getThemeAPI = (id) => {
  return authRequest({
    url: `/themes/${id}`,
    method: 'GET'
  });
};

export const saveThemeAPI = (id, name, html, sass) => {
  return authRequest({
    url: `/themes/${id}`,
    method: 'POST',
    data: {
      name,
      html,
      sass
    }
  });
};

export const saveNewThemeNameAPI = (id, name) => {
  return authRequest({
    url: `/themes/${id}/name`,
    method: 'POST',
    data: {
      name
    }
  });
}
