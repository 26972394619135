import CustomPieChart from './base-classes/custom-pie-chart.jsx';
import {getTimeDuration} from './vis-utils';
import {fetchLastStateAPI} from '../analytics-service';

const lastStateMap = {
  0: 'Ready',
  1: 'Playing',
  2: 'Paused',
  3: 'Ended'
};

class LastStateDistribution extends CustomPieChart {
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchLastState(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration, filter} = nextProps;
    
    if (this.props.activeQuickDuration !== activeQuickDuration || this.props.filter !== filter) {
      this.setState({
        fetchingData: true
      });
      this.fetchLastState(activeQuickDuration, filter);
    }
  }
  fetchLastState (duration, filter) {
    let time = getTimeDuration(duration);
    fetchLastStateAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        this.setState({
          buckets: buckets.map(bucket => ({
            ...bucket,
            key: lastStateMap[bucket.key]
          })),
          fetchingData: false
        });
      })
      .catch(console.log);
  }
}

export default LastStateDistribution;
