import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';

import Header from './header.jsx';
import Login from '../login/login.jsx';
import Home from '../home/home.jsx';
import Themes from '../themes/themes.jsx';
import ThemeEditor from '../theme/theme-editor.jsx';
import Analytics from '../analytics/index.jsx'
import SingleVideoAnalytics from '../analytics/single-video-analytics.jsx';

import './layout.css';

const Layout = ({
  email,
  isLoggedIn,
  onLogout
}) => {
  return (
    <div className='vc-app'>
      <Header isLoggedIn={isLoggedIn} email={email} onLogout={() => onLogout()} />
      <div className='vc-app__container'>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/themes' render={(props) => <Themes {...props} onLogout={() => onLogout()} />} />
          <Route path='/login' component={Login} />
          <Route path='/theme/:themeId' render={(props) => isLoggedIn ? <ThemeEditor {...props} onLogout={() => onLogout()} /> : <Redirect to='/login' />} />
          <Route path='/analytics/:viewId' exact render={(props) => isLoggedIn ? <Analytics {...props} /> : <Redirect to='/login' />} />
          <Route path='/analytics/videos/:videoId' render={(props) => isLoggedIn ? <SingleVideoAnalytics {...props} /> : <Redirect to='/login' />} />
          <Redirect from='/analytics' to='/analytics/overview' />
        </Switch>
      </div>
    </div>
  );
};

export default Layout;
