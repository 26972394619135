import React from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom';

import Loader from '../generic/loader/index.jsx';
import {fetchUserThemes, createNewThemeAPI, deleteThemeAPI} from './themes-list-service';
import CONFIG from '../utils/config';

import './themes-list.css';

const config = CONFIG(process.env.REACT_APP_API_ENV);

const THEME_BUCKET = config.S3_BUCKET;
const THEME_PREFIX = config.S3_PREFIX;

class ThemesList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      themes: [],
      deletingTheme: undefined,
      confirmDeleteTheme: undefined,
      newThemeInputVisible: false,
      createThemeDisabled: true,
      creatingTheme: false,
      redirectToThemeEditor: false,
      newThemeID: ''
    };
  }
  componentWillMount () {
    this.fetchThemes();
  }
  fetchThemes () {
    fetchUserThemes()
      .then(res => {
        this.setState({
          themes: res.data.map(theme => {
            let {createdAt, updatedAt, ...others} = theme;
            createdAt = new Date(createdAt);
            createdAt = createdAt.toDateString() + ' ' + createdAt.toLocaleTimeString();
            updatedAt = new Date(updatedAt);
            updatedAt = updatedAt.toDateString() + ' ' + updatedAt.toLocaleTimeString();
            return {
              ...others,
              createdAt,
              updatedAt
            };
          })
        });
      })
      .catch(err => {
        if (err.status === 401) {
          this.props.onLogout();
          this.props.history.push({
            pathname: '/login',
            search: encodeURI('redirect=/themes')
          });
        } else {
          console.log(err.data);
        }
      });
  }
  showThemeInput () {
    this.setState({
      newThemeInputVisible: true
    });
  }
  hideThemeInput () {
    this.newThemeInput.value = '';
    this.setState({
      newThemeInputVisible: false
    });
  }
  checkNewThemeInput () {
    let newThemeValue = this.newThemeInput.value.trim(), createThemeDisabled = true;
    if (newThemeValue.length > 0) {
      createThemeDisabled = false;
    }
    this.setState({
      createThemeDisabled
    });
  }
  createNewTheme () {
    this.setState({
      createThemeDisabled: true,
      creatingTheme: true
    });
    const newThemeName = this.newThemeInput.value.trim();
    createNewThemeAPI(newThemeName)
      .then(res => this.setState({
        redirectToThemeEditor: true,
        newThemeID: res.data.id
      }))
      .catch(err => console.log((err.response) ? err.response.data : err));
  }
  confirmDelete (themeId) {
    this.setState({
      deletingTheme: themeId,
      confirmDeleteTheme: themeId
    });
  }
  removeConfirmDialog () {
    this.setState({
      deletingTheme: undefined,
      confirmDeleteTheme: undefined
    });
  }
  deleteTheme (themeId) {
    this.setState({
      confirmDelete: undefined
    });
    deleteThemeAPI(themeId)
      .then(res => {
        if (res.status === 200) {
          this.fetchThemes();
          this.setState({
            deletingTheme: undefined
          });
        }
      })
      .catch(err => console.log(err));
  }
  render () {
    const {themes, deletingTheme, confirmDeleteTheme, newThemeInputVisible, createThemeDisabled, creatingTheme, redirectToThemeEditor, newThemeID} = this.state;
    return redirectToThemeEditor ? <Redirect to={`/theme/${newThemeID}`} /> : (
      <div className='vc-app__container'>
        <h1 className='vc-helpers__center'>My Themes</h1>
        <div className='mdc-layout-grid'>
          <div className='mdc-layout-grid__inner'>
            <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-12'>
              <div className='vc-helpers__right'>
                <button className='mdc-button mdc-button--raised' onClick={() => this.showThemeInput()} disabled={newThemeInputVisible}>+ Create a New Theme</button>
                {newThemeInputVisible && <button className='mdc-button mdc-button--raised vc-button__close-theme-input' onClick={() => this.hideThemeInput()}>
                  <i className='material-icons mdc-button__icon'>close</i>
                </button>}
                {newThemeInputVisible && <div className='vc-theme-input'>
                  <div className='mdc-textfield vc-theme-input__text-field-container'>
                    <input className='mdc-textfield__input' onChange={() => this.checkNewThemeInput()} ref={c => this.newThemeInput = c} type='text' placeholder='Enter new theme name' />
                  </div>
                  <button className='mdc-button mdc-button--raised vc-button__create-theme vc-helpers__right' disabled={createThemeDisabled} onClick={() => this.createNewTheme()}>
                    <i className='material-icons mdc-button__icon'>add</i>
                  </button>
                  {creatingTheme && <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-2'>
                    <Loader color='blue' size='small' style={{marginTop: 15}} />
                  </div>}
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className='themes-list-container'>
          {themes.map((theme, idx) => <div key={idx} className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
                <img className='vc-image--responsive' src={`https://s3.amazonaws.com/${THEME_BUCKET}/${THEME_PREFIX}/${theme.id}.png`} alt={`theme ${theme.name}`} />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
                <div className='vc-helpers__right'>
                  <div className='vc-actions--delete-theme'>
                    <button className='mdc-button mdc-button--raised vc-button__delete-theme' disabled={deletingTheme === theme.id} onClick={() => this.confirmDelete(theme.id)}>
                      <i className='material-icons mdc-button__icon'>delete</i>
                    </button>
                    {confirmDeleteTheme === theme.id && <div className='vc-themes__confirm-delete-container'>
                      <span className='vc-themes__confirm-delete-pointer' />
                      <div className='vc-themes__confirm-delete-message'>
                        Are you sure you wanna delete theme {theme.name}?
                        <div className='vc-themes__confirm-delete-buttons'>
                          <button className='yes' onClick={() => this.deleteTheme(theme.id)}>Yes</button>
                          <button className='vc-helpers__right no' onClick={() => this.removeConfirmDialog()}>No</button>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
                <div>
                  <Link className='vc-theme__name' to={`/theme/${theme.id}`}>{theme.name}</Link>
                  <div className='vc-theme__date-container'>
                    <div className='vc-theme__date'>Created: <span>{theme.createdAt}</span></div>
                    <div className='vc-theme__date'>Last Updated: <span>{theme.updatedAt}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    );
  }
};

export default withRouter(ThemesList);
