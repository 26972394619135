import React from 'react';
import {Link} from 'react-router-dom';

import './themes-landing.css';

const ThemesLanding = () => {
  return <div>
    <div className='vc-helpers__center'>
      <h1>Themes</h1>
      <div className='vc-themes__description'>We have community contributed themes to help you use tailor-made themes for your usage.</div>
      <Link className='mdc-button mdc-button--raised' to='/themes/explore'>Explore Now</Link>
    </div>
    <div className='vc-theme-editor__container'>
      <div className='vc-helpers__center'>
        <h2>Theme Editor</h2>
        <div className='vc-theme-editor__description'>Our theme editor can help you modify or tweak a theme you like to your own needs.</div>
      </div>
      <div className='vc-features'>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--live-editing' />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Live Editing & Preview</div>
                  <div className='vc-feature__text--description'>Get instant preview of your theme on changing the HTML and CSS.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Theme Paramters</div>
                  <div className='vc-feature__text--description'>Don't know SASS or HTML? You can change the basic parameters like colors or padding without knowing how to write code.</div>
                </div>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--theme-parameters' />
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--ludicrous-mode' />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Ludicrous Mode</div>
                  <div className='vc-feature__text--description'>If you are a pro, you can turn on `Ludicrous` mode and get right in to make the theme pixel perfect.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Docs</div>
                  <div className='vc-feature__text--description'>There is a lot of documentation available on using themes and likewise to add them to your videos.</div>
                </div>
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__image vc-feature__image--docs' />
              </div>
            </div>
          </div>
        </div>
        <div className='vc-feature'>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-8' />
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-4'>
                <div className='vc-feature__text'>
                  <div className='vc-feature__text--header'>Start using the editor now!</div>
                  <Link to='/themes/explore' className='mdc-button mdc-button--raised'>Explore</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default ThemesLanding;
