import {getFromStorage, removeFromStorage} from '../storage';

export const getAccessToken = () => getFromStorage('accessToken');

export const getUserInfo = () => getFromStorage('ssoToken') ? JSON.parse(atob(getFromStorage('ssoToken'))) : {};

export const removeAuthentication = () => {
  removeFromStorage('accessToken');
  removeFromStorage('ssoToken');
};
