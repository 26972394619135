import React from 'react';
import {setTheme, subscribe} from '../mpml';
import {withRouter, Link} from 'react-router-dom';

import ParamsEditor from './components/params-editor.jsx';
import Controller from './controller/index.jsx';
import Loader from '../generic/loader/index.jsx';

import './theme-editor.css';

import {getThemeAPI, saveThemeAPI, saveNewThemeNameAPI} from './theme-service';
import {saveToStorage, removeFromStorage, getFromStorage} from '../utils/storage';
import {goToEl} from '../utils/markup';

let mpmlObject = {
  duration: 3140,
  currentTime: 120,
  bufferLength: 400,
  status: 2,
  isBuffering: false,
  playbackRate: 1,
  availablePlaybackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
  volume: 0.4,
  hasError: false,
  errorMessage: '',
  title: 'Hello, world!',
  description: 'This is a sample description.',
  isFullscreen: false,
  menu: "topLevel",
  abrEnabled: 1,
  activeVariant: {
    "id": 900,
    "label": "SD",
  },
  availableQualities: [{
    "id": 3,
    "label": "Low",
  }, {
    "id": 4,
    "label": "SD",
  }, {
    "id": 5,
    "label": "HD",
  }]
};
class ThemeEditor extends React.Component {
  constructor (props) {
    super(props);

    this.onChangeTimer = undefined;
    this.state = {
      editingMode: 'ludicrous',
      // editingMode: getFromStorage('editingMode') || 'simple',
      confirmChangeEditingMode: undefined,
      isEditingName: false,
      errorMessage: '',
      leftDocsNavClassName: '',
      markers: [],
      html: '',
      sass: '',
      compiledSass: '',
      name: '',
      updatedAt: null,
      markupTab: 'sassParams',
      controlTab: 'panel',
      sassValues: {},
      eventLogs: [],
      unreadLogCount: 0,
      sassError: '',
      isSaving: false,
      colorPickerShown: false
    };
    this._onWindowScroll = (e) => this.onWindowScroll(e);
  }
  componentWillMount () {
    const htmlSaved = getFromStorage('html'),
      sassSaved = getFromStorage('sass'),
      nameSaved = getFromStorage('name'),
      updatedAtSaved = getFromStorage('updatedAt');
    if (htmlSaved !== null) {
      this.setStateAndSetPreview({
        html: htmlSaved,
        sass: sassSaved,
        name: nameSaved,
        updatedAt: updatedAtSaved
      }, () => this.onSaveThemeClick());
    }
    const {themeId} = this.props.match.params;
    getThemeAPI(themeId)
      .then(res => {
        const {html, sass, name, updatedAt} = res.data;
        this.setStateAndSetPreview({
          html,
          sass,
          name,
          updatedAt
        });
      })
      .catch(err => console.log(err));
  }
  componentDidMount () {
    this.unsubscribe = subscribe((logObj) => this.addEventLogs(logObj));
    window.addEventListener('scroll', this._onWindowScroll);
  }
  componentWillUnmount () {
    this.unsubscribe();
    window.removeEventListener('scroll', this._onWindowScroll);
  }
  editName () {
    this.setState({
      isEditingName: true
    }, () => this.editNameInput.focus());
  }
  saveName (e) {
    let newThemeName = e.target.value.trim();
    if (newThemeName === this.state.name) {
      this.setState({
        isEditingName: false
      });
      return;
    }
    const {themeId} = this.props.match.params;
    saveNewThemeNameAPI(themeId, newThemeName)
      .then(res => {
        this.setState({
          name: newThemeName,
          isEditingName: false
        });
      })
      .catch(err => {
        this.setErrorMessage(err.data.message);
        this.setState({
          isEditingName: false
        });
        window.setTimeout(() => this.removeErrorMessage(), 4000);
      });
  }
  setErrorMessage (msg) {
    this.setState({
      errorMessage: msg
    });
  }
  removeErrorMessage () {
    this.setState({
      errorMessage: ''
    });
  }
  setStateAndSetPreview (themeObject, cb) {
    const {html, sass, name, updatedAt} = themeObject;
    const markers = this.getMPMLAttributes(html);
    this.setState({
      markers,
      html,
      sass,
      name,
      updatedAt: new Date(updatedAt)
    }, () => {
      this.setThemePreview({html: this.state.html, sass: this.state.sass}, 'vc-theme__preview-container', mpmlObject);
      if (typeof cb !== 'undefined') {
        cb();
      }
    });
  }
  removeSavedThemeFromStorage () {
    removeFromStorage('html');
    removeFromStorage('sass');
    removeFromStorage('name');
    removeFromStorage('updatedAt');
  }
  onSaveThemeClick () {
    const {themeId} = this.props.match.params;
    const {html, sass, name} = this.state;
    const updatedAt = new Date();
    this.setState({
      isSaving: true
    });
    saveThemeAPI(themeId, name, html, sass)
      .then(res => {
        this.removeSavedThemeFromStorage();
        this.setState({
          isSaving: false,
          updatedAt
        });
      })
      .catch(err => {
        this.setState({
          isSaving: false
        });
        if (err.status === 401) {
          const {html, sass, name, updatedAt} = this.state;
          saveToStorage('html', html);
          saveToStorage('sass', sass);
          saveToStorage('name', name);
          saveToStorage('updatedAt', updatedAt);
          this.props.history.push({
            pathname: '/login',
            search: `redirect=/themes/${this.props.match.params.themeId}`
          });
        }
        console.log(err);
      });
  }
  setThemePreview (markupObject, ele, mpmlObject, component) {
    const {html, sass} = markupObject;
    if (component === 'html') {
      const {compiledSass} = this.state;
      setTheme({html, css: compiledSass}, document.getElementById(ele), mpmlObject, {});
    } else {
      import('sass.js')
        .then((Sass) => {
          Sass.compile(sass, (res) => {
            if (res.status === 0) {
              this.setState({
                sassError: '',
                compiledSass: res.text
              });
              this.setSassValues(sass);
              setTheme({html, css: res.text}, document.getElementById(ele), mpmlObject, {});
            } else {
              this.setState({
                sassError: res.message
              });
            }
          });
        });
    }
  }
  getValueType (value) {
    if (value.startsWith('#')) {
      return 'color';
    } else if (/^[0-9]+px$/.test(value)) {
      return 'number';
    } else if (value === 'true' || value === 'false') {
      return 'boolean';
    } else {
      return 'generic';
    }
  }
  setSassValues (sass) {
    let sassLines = sass.split('\n'), variableLine;
    let sassValues = {};
    for (let line of sassLines) {
      // check if line is a comment or is empty
      if (line.startsWith('//') || line === '') {
        continue;
      } else if (line.match(/^\$([a-zA-Z0-9]+)\s*:\s*([#a-zA-Z0-9]+)\s*;$/) === null) {
        break;
      } else {
        variableLine = line.match(/^\$([a-zA-Z0-9]+)\s*:\s*([#a-zA-Z0-9]+)\s*;$/);
        sassValues[variableLine[1]] = {
          value: variableLine[2],
          type: this.getValueType(variableLine[2]),
          line: sassLines.indexOf(line) + 1
        };
      }
    }
    this.setState({
      sassValues
    });
  }
  showColorPicker () {
    this.setState({
      colorPickerShown: true
    });
  }
  hideColorPicker () {
    this.setState({
      colorPickerShown: false
    });
  }
  getMPMLAttributes (html) {
    let markers = [];
    let mpmlAttributeRegex = /mpml-[^=\s]+="[^"]+"/g;
    let htmlLines = html.split('\n');
    for (let line of htmlLines) {
      let mpmlMatches = line.match(mpmlAttributeRegex);
      if (mpmlMatches) {
        let startRow = htmlLines.indexOf(line);
        let endRow = startRow;
        for (let match of mpmlMatches) {
          let startCol = line.indexOf(match);
          let endCol = startCol + match.length;
          markers.push({
            startRow,
            startCol,
            endRow,
            endCol,
            className: 'error-marker',
            type: 'background'
          });
        }
      }
    }
    return markers;
  }
  addEventLogs (logObj) {
    let {eventLogs, controlTab, unreadLogCount} = this.state;
    logObj = {
      ...logObj,
      time: new Date(logObj.time)
    };
    if (controlTab !== 'log') {
      unreadLogCount++;
      this.setState({
        unreadLogCount
      });
    }
    if (eventLogs.length === 0) {
      eventLogs.push({
        count: 0,
        ...logObj
      });
    } else {
      let lastEvent = eventLogs[eventLogs.length - 1];
      if (lastEvent.eventType === logObj.eventType && lastEvent.eventHandler === logObj.eventHandler) {
        lastEvent.count += 1;
      } else {
        eventLogs.push({
          count: 0,
          ...logObj
        });
      }
    }

    this.setState({
      eventLogs
    });
  }
  scrollTo (el) {
    window.setTimeout(() => {
      let pos = el;
      if (typeof el === 'string') {
        pos = document.querySelector(el).getBoundingClientRect().top - 20;
      }
      goToEl(pos);
    }, 100);
  }
  onWindowScroll () {
    let {scrollTop} = document.body;
    let {leftDocsNavClassName} = this.state;
    let scrollThreshold = 862;
    leftDocsNavClassName = scrollTop > scrollThreshold ? ' fixed' : '';
    this.setState({
      leftDocsNavClassName
    });
  }
  onChangeMarkup (newVal, component) {
    if (typeof this.onChangeTimer !== 'undefined') {
      clearTimeout(this.onChangeTimer);
    }
    this.onChangeTimer = setTimeout(() => {
      let {markers} = this.state;
      if (component === 'html') {
        markers = this.getMPMLAttributes(newVal);
      }

      this.setState({
        markers,
        [component]: newVal
      }, () => this.setThemePreview({html: this.state.html, sass: this.state.sass}, 'vc-theme__preview-container', mpmlObject, component));
    }, 300);
  }
  onChangeSassValueType (type, key) {
    let {sassValues} = this.state;
    sassValues = {
      ...sassValues,
      [key]: {
        ...sassValues[key],
        type
      }
    };
    this.setState({
      sassValues
    });
  }
  onChangeSassValues (newVal, line) {
    let {sass, sassValues} = this.state, sassLine, sassLineParams;
    sassLine = sass.split('\n')[line - 1];
    sassLineParams = sassLine.match(/^\$([a-zA-Z0-9]+)\s*:\s*([#a-zA-Z0-9]+)\s*;$/);
    if (newVal.length === 0) {
      return;
    }
    let {type} = sassValues[sassLineParams[1]];
    sassLine = `$${sassLineParams[1]}: ${type === 'number' ? newVal + 'px' : newVal};`;
    let lines = sass.split('\n');
    sass = [
      ...lines.slice(0, line - 1),
      sassLine,
      ...lines.slice(line)
    ].join('\n');
    this.onChangeMarkup(sass, 'sass');
  }
  onChangeMPMLGUI (field, newVal) {
    mpmlObject[field] = newVal;
    this.forceUpdate();
  }
  onChangeMPMLJSON (editor) {
    mpmlObject = JSON.parse(editor.editor.getValue());
    const {html, sass} = this.state;
    this.setThemePreview({html, sass}, 'vc-theme__preview-container', mpmlObject);
    this.forceUpdate();
  }
  onMarkupTabClick (val) {
    this.setState({
      markupTab: val
    });
  }
  onControlTabClick (name) {
    this.setState({
      controlTab: name
    }, () => {
      if (name === 'log') {
        this.setState({
          unreadLogCount: 0
        });
      } else if (name === 'panel') {
        this.setControlPanelUIElements();
      }
    });
  }
  onHelpClick () {
    this.scrollTo('.docs-container');
  }
  render () {
    const {editingMode, isEditingName, errorMessage, markers, html, sass, name, markupTab, controlTab, unreadLogCount, eventLogs, sassError, sassValues, isSaving, colorPickerShown} = this.state;
    return (
      <div className='vc-app__container'>
        {errorMessage !== '' && <div className='vc-helpers__center'>
          <div className='vc-theme__error-message-container'>
            <div className='vc-theme__error-message'>{errorMessage}</div>
          </div>
        </div>}
        <div className='vc-theme__container'>
          <div className='vc-theme__theme-name-container'>
            <div className='vc-theme__theme-name'>
              <span className='vc-theme__theme-heading'>Theme:</span>
              {!isEditingName && <span className='name' onClick={() => this.editName()}>
                <span>{name}</span>
                <i style={{verticalAlign: 'bottom'}} className='material-icons'>edit</i>
              </span>}
              {isEditingName && <div className='mdc-textfield'>
                <input ref={c => this.editNameInput = c} className='mdc-textfield__input vc-theme__edit-theme-name' type='text' defaultValue={name} onBlur={e => this.saveName(e)} />
              </div>}
            </div>
          </div>
          <div className='mdc-layout-grid'>
            <div className='mdc-layout-grid__inner'>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-5'>
                <div className='vc-theme__preview'>
                  <div id='vc-theme__preview-container' />
                </div>
                <Controller
                  unreadLogCount={unreadLogCount}
                  currentTab={controlTab}
                  mpmlObject={mpmlObject}
                  eventLogs={eventLogs}
                  onControlTabClick={name => this.onControlTabClick(name)}
                  onChangeMPMLGUI={(field, newVal) => this.onChangeMPMLGUI(field, newVal)}
                  onChangeMPMLJSON={mpmlEditor => this.onChangeMPMLJSON(mpmlEditor)} />
              </div>
              <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-7'>
                <div className='vc-theme__toolbar vc-helpers__right-align'>
                  <Link className='mdc-button mdc-button--raised' onClick={() => this.scrollTo('.docs-container')} to={`/themes/${this.props.match.params.themeId}/how-it-works`}><i className='mdc-button__icon material-icons'>help</i> Help</Link>
                  {/*<div>
                    <button className='btn' onClick={() => this.onUseClick()}><i className='fa fa-leaf' /> Use</button>
                  </div>*/}
                  <div style={{display: 'inline-block', marginLeft: 15}}>
                    {isSaving && <Loader size='small' color='blue' style={{verticalAlign: 'bottom', marginRight: 10}} />}
                    {/*updatedAt && <span>Last Updated: {updatedAt.toLocaleString()}</span>*/}
                    <button disabled={isSaving} className='mdc-button mdc-button--raised' onClick={() => this.onSaveThemeClick()}><i className='mdc-button__icon material-icons'>save</i> Save</button>
                  </div>
                </div>
                <ParamsEditor
                  editingMode={editingMode}
                  currentTab={markupTab}
                  markers={markers}
                  html={html}
                  sass={sass}
                  sassValues={sassValues}
                  sassError={sassError}
                  mpmlObject={mpmlObject}
                  colorPickerShown={colorPickerShown}
                  showColorPicker={() => this.showColorPicker()}
                  hideColorPicker={() => this.hideColorPicker()}
                  onSave={() => this.onSaveThemeClick()}
                  onMarkupTabClick={mode => this.onMarkupTabClick(mode)}
                  onChangeSassValueType={(type, key) => this.onChangeSassValueType(type, key)}
                  onChangeSassValues={(newVal, line) => this.onChangeSassValues(newVal, line)}
                  onChangeMarkup={(newVal, mode) => this.onChangeMarkup(newVal, mode)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ThemeEditor);
