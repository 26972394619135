import React, {Component} from 'react';

import {getTimeDuration} from './vis-utils';
import {fetchPopularVideosAPI} from '../analytics-service';

class PopularVideos extends Component {
  constructor (props) {
    super(props);

    this.state = {
      popularVideosList: []
    };
  }
  componentWillMount () {
    let {activeQuickDuration} = this.props;
    this.fetchPopularVideos(activeQuickDuration);
  }
  componentWillReceiveProps (nextProps) {
    let {activeQuickDuration, filter} = nextProps;
    if (this.props.activeQuickDuration !== activeQuickDuration || this.props.filter !== filter) {
      this.fetchPopularVideos(activeQuickDuration, filter);
    }
  }
  fetchPopularVideos (duration, filter) {
    let time = getTimeDuration(duration);
    fetchPopularVideosAPI({
      time,
      showViewedOnly: filter === 'view'
    })
      .then(res => {
        let {buckets} = res.data.aggregations[2];
        this.setState({
          popularVideosList: buckets.slice(0, 10)
        });
      })
      .catch(err => console.log(err));
  }
  render () {
    let {popularVideosList} = this.state;
    let {filter} = this.props;
    return (
      <table className='vc-analytics__table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>{filter === 'load' ? 'Load' : 'View'} Count</th>
          </tr>
        </thead>
        <tbody>
          {popularVideosList.map((video, index) => <tr key={index}>
            <td>{typeof video[3].buckets[0] !== 'undefined' ? video[3].buckets[0].key : video.key}</td>
            <td>{video.doc_count}</td>
          </tr>)}
        </tbody>
      </table>
    );
  }
}

export default PopularVideos;
