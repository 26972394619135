const responseSuccess = (response) => {
  return Promise.resolve(response);
};

const responseFailure = (err) => {
  return Promise.reject(err.response);
};

export const response = {
  success: responseSuccess,
  failure: responseFailure
};
