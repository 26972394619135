import React from 'react';
// import ReactDOM from 'react-dom';
import {NavLink, Link} from 'react-router-dom';

import Gravatar from '../gravatar/gravatar.jsx';

import './header.css';

class Header extends React.Component {
  toggleProfileMenu (evt) {
    let openClass = 'mdc-simple-menu--open';
    if (evt.type === 'mouseover') {
      this.profileMenu.classList.add(openClass);
    } else if (evt.type === 'mouseout') {
      this.profileMenu.classList.remove(openClass);
    }
  }
  render () {
    let {isLoggedIn, email, onLogout} = this.props;
      return (
        <header className='mdc-toolbar'>
          <div className='mdc-toolbar__row'>
            <section className="mdc-toolbar__section mdc-toolbar__section--align-start">
              <Link to='/' className="mdc-toolbar__title vc-logo">The Player</Link>
              {/*<NavLink to='/themes' activeClassName='active' className='mdc-toolbar__title'>Themes</NavLink>*/}
              {/*<NavLink to='/plugins' activeClassName='active' className='mdc-toolbar__title'>Plugins</NavLink>*/}
              <NavLink to='/analytics' activeClassName='active' className='mdc-toolbar__title'>Analytics</NavLink>
            </section>
            <section className='mdc-toolbar__section mdc-toolbar__section--align-end'>
              {isLoggedIn && <div className='vc-profile__container mdc-menu-anchor'
                  onMouseOver={(e) => this.toggleProfileMenu(e)}
                  onMouseOut={(e) => this.toggleProfileMenu(e)}>
                <Gravatar email={email} />
                <div ref={c => this.profileMenu = c} className='mdc-simple-menu mdc-simple-menu--open-from-bottom-right' tabIndex='-1'>
                  <ul className='mdc-simple-menu__items mdc-list' role='menu' aria-hidden={true}>
                    <li className='mdc-list-item vc--no-cursor' role='menuitem' tabIndex='0'>{email}</li>
                    <li role="separator" className="mdc-list-divider" />
                    {/* <li className='mdc-list-item' role='menuitem' tabIndex='0' onClick={() => ReactDOM.findDOMNode(this.myThemesLink).click()}>
                      <Link to='/themes/my' ref={c => this.myThemesLink = c}>My Themes</Link>
                    </li> */}
                    <li className='mdc-list-item' role='menuitem' tabIndex='0' onClick={() => onLogout()}>Logout</li>
                  </ul>
                </div>
              </div>}
              {!isLoggedIn && <Link className="mdc-button mdc-button--raised mdc-button--primary" to='/login'>
                Login
              </Link>}
            </section>
          </div>
        </header>
      );
  }
}

export default Header;
