import axios from 'axios';

import {response} from './interceptors';
import {getAccessToken} from '../auth';
import CONFIG from '../config';

const env = process.env.REACT_APP_API_ENV || 'dev';
const baseURL = CONFIG(env).API_URL;

const request = axios.create({
  baseURL
});

const {success, failure} = response;
request.interceptors.response.use(success, failure);

export const simpleRequest = ({
  url,
  method,
  data
}) => {
  return request({
    url,
    method,
    data
  });
};

export const authRequest = ({
  url,
  method,
  data
}) => {
  const accessToken = getAccessToken();
  return request({
    url,
    method,
    data,
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
};

export const parallelRequests = (requests) => {
  return axios.all(requests);
};
