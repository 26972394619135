import React from 'react';
import {Redirect} from 'react-router-dom';

import {loginAPI, saveTokenAPI, saveSSOTokenAPI} from './login-service';

class SSOLogin extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      shouldRedirect: false,
      redirectUrl: '/'
    };
  }

  componentDidMount () {
    let {search} = this.props.location;
    let redirectUrl = search.slice(1).split('=')[1];
    const {ssoToken} = this.props.match.params;
    let [payload, sign] = ssoToken.split(':');
    saveSSOTokenAPI(payload);

    loginAPI(payload, sign)
      .then(res => this.saveAccessToken(res.data, redirectUrl))
      .catch(err => console.log(err));
  }

  saveAccessToken ({accessToken}, redirectUrl = '/') {
    saveTokenAPI(accessToken);
    this.setState({
      shouldRedirect: true,
      redirectUrl
    });
  }

  render () {
    const {shouldRedirect, redirectUrl} = this.state;
    return shouldRedirect ? <Redirect to={redirectUrl} /> : <div />;
  }
}

export default SSOLogin;
