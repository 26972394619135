import dev from './dev';
import stag from './stag';
import prod from './prod';

const env = {
  dev,
  stag,
  prod,
  testing: dev
};

export default function (processEnv) {
  return env[processEnv];
}
