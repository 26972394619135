import React, {Component} from 'react';

class LoadViewToggle extends Component {
  constructor (props) {
    super(props);

    this.state = {
      filter: 'load'
    };
  }
  toggleFilter () {
    let {filter} = this.state;
    this.setState({
      filter: filter === 'load' ? 'view' : 'load'
    }, () => this.props.onFilterChange(this.state.filter));
  }
  render () {
    let {filter} = this.state;
    return <div className='vc-analytics__load-view-container vc-helpers__left'>
      <label style={{color: '#2196F3'}} htmlFor="load-view-toggle" className="mdc-switch-label">Viewed</label>
      <div className='mdc-switch'>
        <input type='checkbox' id='load-view-toggle' className='mdc-switch__native-control' defaultChecked={filter === 'load'} onClick={() => this.toggleFilter()} />
        <div className="mdc-switch__background">
          <div className="mdc-switch__knob" />
        </div>
      </div>
      <label style={{color: '#018786'}} htmlFor="load-view-toggle" className="mdc-switch-label">Loaded</label>
    </div>;
  }
}

export default LoadViewToggle;
