import React, {Component} from 'react';
import {Cell, PieChart, Pie, Tooltip} from 'recharts';

const COLORS = ['#3F51B5', '#FF9800', '#2196F3', '#CDDC39', '#4CAF50'];
const tooltipWrapperStyle = {
  color: '#4A4A4A',
  backgroundColor: '#FEFEFE',
  padding: 10,
  borderRadius: 3,
  border: '1px solid #4A4A4A'
};

class CustomPieChart extends Component {
  constructor (props) {
    super(props);
    
    this.state = {
      buckets: [],
      fetchingData: true
    };
  }
  customLabel ({name, x, y, textAnchor, fill}) {
    if (textAnchor === 'end') {
      if (name === undefined) {
        name = 'Unknown';
      }
      x = x - (name.toString().length * 7.5);
    } else {
      y = y + 5;
    }
    return <text x={x} y={y} fontSize={12} style={{fill}}>{name}</text>;
  }
  customTooltip ({payload}) {
    return payload.length > 0 ? payload[0].payload.percentage.toFixed(2) + '%' : '';
  }
  getDistributionFromBuckets (buckets) {
    let totalCount = buckets.reduce((total, bucket) => total + bucket.doc_count, 0);
    let distribution = buckets.map(bucket => ({
      name: bucket.key,
      value: bucket.doc_count,
      percentage: bucket.doc_count * 100 / totalCount
    }));
    return distribution;
  }
  render () {
    let {buckets} = this.state;
    let distribution = this.getDistributionFromBuckets(buckets);
    let pieChart = <PieChart width={325} height={200}>
      <Pie
        data={distribution}
        dataKey="value"
        nameKey="name"
        label={this.customLabel.bind(this)}
        paddingAngle={1}
        isAnimationActive={true}
        cx="50%"
        cy="50%"
        outerRadius={70}>
        {distribution.map((val, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)}
      </Pie>
      <Tooltip
        content={this.customTooltip.bind(this)}
        wrapperStyle={tooltipWrapperStyle} />
    </PieChart>;
    return buckets.length > 0
      ? pieChart
      : <div className='vc-analytics__pie-label'>No data found</div>;
  }
}

export default CustomPieChart;
