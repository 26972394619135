import React from 'react';

const statusList = [
  'Ready',
  'Playing',
  'Paused',
  'Ended'
];

const Panel = ({
  currentTime,
  duration,
  volume,
  status,
  isBuffering,
  availableQualities,
  onChangeMPMLGUI
}) => {
  const availableQualitiesValue = availableQualities.map(quality => quality.label).join(', ');
  return (
    <div className='vc-theme__control-panel'>
      <div className='vc-theme__panel-header'>Change preview state</div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Progress</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
            <div id='progress-slider' className='mdc-slider' tabIndex={0} role="slider" aria-valuemin={0} aria-valuemax={duration} aria-valuenow={currentTime}>
              <div className='mdc-slider__track-container'>
                <div className='mdc-slider__track' />
              </div>
              <div className='mdc-slider__thumb-container'>
                <svg className='mdc-slider__thumb' width={21} height={21}>
                  <circle cx={10.5} cy={10.5} r={7.875} />
                </svg>
                <div className='mdc-slider__focus-ring' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Duration</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
            <div className='mdc-textfield mdc-textfield--fullwidth field-value'>
              <input className='mdc-textfield__input' type='number' defaultValue={duration} onChange={e => onChangeMPMLGUI('duration', parseInt(e.target.value, 10))} />
            </div>
          </div>
        </div>
      </div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Vol</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
            <div id='volume-slider' className='mdc-slider' tabIndex={0} role="slider" aria-valuemin={0} aria-valuemax={1} aria-valuenow={volume} data-step={0.1}>
              <div className='mdc-slider__track-container'>
                <div className='mdc-slider__track' />
              </div>
              <div className='mdc-slider__thumb-container'>
                <svg className='mdc-slider__thumb' width={21} height={21}>
                  <circle cx={10.5} cy={10.5} r={7.875} />
                </svg>
                <div className='mdc-slider__focus-ring' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Status</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
            <select defaultValue={status} className='mdc-select' onChange={e => onChangeMPMLGUI('status', parseInt(e.target.value, 10))}>
              {statusList.map((statusText, index) => <option key={index} value={index}>{statusText}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Buffering</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9 vc-helpers__center'>
            <div className="mdc-checkbox">
              <input type='checkbox' id='video-buffering' defaultChecked={isBuffering} onChange={e => onChangeMPMLGUI('isBuffering', e.target.checked)}/>
            </div>
          </div>
        </div>
      </div>
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-3'>
            <div className='field-label'>Quality List</div>
          </div>
          <div className='mdc-layout-grid__cell mdc-layout-grid__cell--span-9'>
            <div className='mdc-textfield mdc-textfield--fullwidth mdc-textfield--disabled'>
              <input className='mdc-textfield__input' type='text' disabled={true} defaultValue={availableQualitiesValue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
