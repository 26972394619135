import {simpleRequest} from '../utils/api';
import {saveToStorage} from '../utils/storage';

export const loginAPI = (loginData, sign) => {
  return simpleRequest({
    url: '/user/sso',
    method: 'POST',
    data: {
      loginData,
      sign
    }
  });
};

export const googleLoginAPI = (idToken) => {
  return simpleRequest({
    url: '/user/googleauth',
    method: 'POST',
    data: {
      idToken
    }
  });
};

export const saveTokenAPI = (token) => {
  saveToStorage('accessToken', token);
};

export const saveSSOTokenAPI = (ssoToken) => {
  saveToStorage('ssoToken', ssoToken);
};
