import React from 'react';
import {LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line} from 'recharts';

const COLORS = ['#3F51B5', '#FF9800', '#2196F3', '#CDDC39', '#4CAF50'];

const CustomizedAxisTick = ({x, y, payload}) => {
  return (
    <g transform={`translate(${x + 50},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={12}>{payload.value}</text>
    </g>
  );
};

const CustomLabel = ({ label, viewBox }) => {
  let {x, height} = viewBox;
  x = x || 0;
  return <text
    className='recharts-text recharts-label'
    x={x}
    y={height / 2}
    transform={`rotate(-90, ${x - 10}, ${height / 2})`}
    textAnchor="middle"
    fill='#BCBCBC'>
    <tspan x={x} dy={12}>{label}</tspan>
  </text>;
};

const VCLineChart = ({lineData, fields, label, width, left, right}) => {
  if (width === 0) {
    width = window.innerWidth;
    left = 0.05 * width;
    right = 0.05 * width;
  }
  return <LineChart width={width} height={250} data={lineData} margin={{left, right}}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="key" tick={<CustomizedAxisTick />} />
    <YAxis label={<CustomLabel label={label} />} />
    <Tooltip />
    <Legend />
    {fields.map((field, idx) => <Line key={idx} type='monotone' name={field} dataKey={field} stroke={COLORS[idx]} />)}
  </LineChart>;
};

export default VCLineChart;
