import {simpleRequest, authRequest} from '../utils/api';

export const fetchCommunityThemesAPI = () => {
  return simpleRequest({
    url: '/themes/communityThemes',
    method: 'GET'
  });
};

export const forkCommunityThemeAPI = (themeId) => {
  return authRequest({
    url: `/themes/${themeId}`,
    method: 'PUT'
  });
};
