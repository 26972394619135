const h = 60 * 60 * 1000;
const d = 24 * h;
const w = 7 * d;
const m = 4 * w;

const unitIntervalMap = {
  '4h': 4 * h,
  '12h': 12 * h,
  '1d': d,
  '3d': 3 * d,
  '1w': w,
  '2w': 2 * w,
  '1m': m,
  '3m': 3 * m,
  '6m': 6 * m
};

export const getTimeDuration = (duration) => {
  let currentTime = new Date().getTime();
  let previousTime = currentTime - unitIntervalMap[duration];
  
  return {
    lte: currentTime,
    gte: previousTime
  }
};
