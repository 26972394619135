import {fetch} from '../http-utils';
import {configureRivets} from './rivets-utils';
import resetCSS from './reset.scss';

export const isValidURL = (str) => str.startsWith('https://') || str.startsWith('http://');

const CONTAINERCLASS = 'vc-container';

/**
 * injects html by creating a container inside the given element
 * @param {DOM} ele
 * @param {string} htmlString
 * @param {object} mpmlObject
 * @param {object} options
 * @param {boolean?} [rewrite=false] rewrite
 */
export const injectHTML = (
  ele, htmlString, mpmlObject, options, rewrite = false
) => {
  let existingContainer = ele.getElementsByClassName(CONTAINERCLASS);
  let htmlEle;
  switch (existingContainer.length) {
    case 0:
      htmlEle = document.createElement('div');
      htmlEle.className = CONTAINERCLASS;
      htmlEle.style.width = '100%';
      htmlEle.style.height = '100%';
      htmlEle.style.position = 'absolute';
      htmlEle.style.zIndex = 1;
      let docFrag = document.createDocumentFragment();
      htmlEle.innerHTML = htmlString;
      docFrag.appendChild(htmlEle);
      ele.insertBefore(docFrag, ele.childNodes[0]);
      break;

    case 1:
      htmlEle = existingContainer[0];
      if (rewrite) {
        htmlEle.innerHTML = '';
      }
      htmlEle.innerHTML += htmlString;
      break;

    default:
      throw new Error('Invalid contents found.');
  }

  configureRivets(ele, mpmlObject, options);
};

export const injectResetCSS = (ele) => {
  const STYLEID = 'resetStyle';
  let cssText = document.createTextNode(resetCSS.toString());
  let styleEle = document.getElementById(STYLEID);
  if (!styleEle) {
    styleEle = document.createElement('style');
    styleEle.id = STYLEID;
    ele.insertBefore(styleEle, ele.childNodes[0]);
  }
  styleEle.innerHTML = '';
  styleEle.appendChild(cssText);
};

export const injectCSS = (ele, cssString) => {
  const STYLEID = 'previewStyle';
  let cssText = document.createTextNode(cssString);
  let styleEle = document.getElementById(STYLEID);
  if (!styleEle) {
    styleEle = document.createElement('style');
    styleEle.id = STYLEID;
    ele.insertBefore(styleEle, ele.childNodes[0]);
  }
  styleEle.innerHTML = '';
  styleEle.appendChild(cssText);
};

export const fetchHTML = (htmlString) => {
  if (isValidURL(htmlString)) {
    return fetch('GET', htmlString)
      .then((res) => res)
      .catch((err) => console.log(err));
  } else {
    return new Promise((resolve) => {
      resolve(htmlString);
    });
  }
};

export const fetchCSS = (cssString) => {
  if (isValidURL(cssString)) {
    return fetch('GET', cssString)
      .then((res) => res)
      .catch((err) => console.log(err));
  } else {
    return new Promise((resolve) => {
      resolve(cssString);
    });
  }
};

export {configureRivets};
